import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { AngularFireAuth } from 'angularfire2/auth';
import { AuthServiceProvider } from '../providers/auth-service/auth-service';
import { UserServiceProvider, User } from '../providers/user-service/user-service';
import * as firebase from 'firebase';
import { AngularFireObject, AngularFireDatabase } from 'angularfire2/database';
import { DatabaseService } from './../database.service';

@Component({
  selector: 'app-novasenha',
  templateUrl: './novasenha.component.html',
  styleUrls: ['./novasenha.component.scss']
})
export class NovasenhaComponent implements OnInit {

  dados: User;
  alterarsenhaForm: FormGroup;
  public contact: User;

  constructor(
    public formBuilder: FormBuilder,
    public afa: AngularFireAuth,
    public authservice: AuthServiceProvider,
    public contactProvider: UserServiceProvider,
    public userService: UserServiceProvider,
    public database: DatabaseService,
    public angularFirebase: AngularFireDatabase) {
    let uuid: string = this.afa.auth.currentUser.uid;
    this.angularFirebase.database.ref('users/' + this.afa.auth.currentUser.uid).once('value').then(val => {
      this.contact = val.val()
    })

    this.alterarsenhaForm = this.formBuilder.group({
      senha: ['', [Validators.required, Validators.minLength(6)]],
      nsenha: ['', [Validators.required, Validators.minLength(6)]],
      csenha: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  ngOnInit() {
  }

  mudanca() {

    let FormSenhaUser = this.alterarsenhaForm.value;
    let uuid: string = this.afa.auth.currentUser.uid;
    let email = this.contact.email;
    let user = firebase.auth().currentUser;

    let senha = FormSenhaUser.senha;
    let nsenha = FormSenhaUser.nsenha;

    this.dados = this.contact;

    this.userService.change(this.dados, uuid)
      .then(() => {


        if (nsenha == '') {

        }
        else {
          this.authservice.signinWithEmail({ email, senha }).then(() => {
            user.updatePassword(nsenha).then(() => {
            }).catch((error) => {
            })
          }).catch((error) => {
          })
        }

      })

  }







}

