import { AngularFireAuth } from 'angularfire2/auth';
import { DatabaseService } from './database.service';
import { Component, NgZone } from '@angular/core';
import { ParametrosService } from './parametros.service';
import { Router } from '@angular/router';
import { PropostaService } from './proposta.service';
import { UserServiceProvider } from './providers/user-service/user-service'
import * as firebase from 'firebase';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'solar21';


  constructor(public zone: NgZone, public proposta: PropostaService, public afa: AngularFireAuth, public users: UserServiceProvider, public user: UserServiceProvider, public dbService: DatabaseService, public paramService: ParametrosService, public router: Router) {

    this.router.navigate(['login'])

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.zone.run(async () => {
          this.dbService.getInversores();
          this.dbService.historicoPropostas();
          this.dbService.getUsers();
          await this.router.navigate(['proposta'])
        })
      }
    })

  }

  ngOnInit() {



  }
}
