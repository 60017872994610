import { Proposta } from './proposta';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { DatabaseService } from './database.service';

@Injectable({
  providedIn: 'root'
})
export class PropostaService {
  public imagem;
  public proposta: Proposta;
  public propostas: Proposta[] = [];
  constructor() { }
}

