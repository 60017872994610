import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args) {
      return value;
    }
    return value.filter((val) => {
      let rVal = ((val.cliente.nome.toLocaleLowerCase().includes(args)) || (val.cliente.email.toLocaleLowerCase().includes(args)) || (val.cliente.contrato.toLocaleLowerCase().includes(args)) || (val.cliente.numero.toLocaleLowerCase().includes(args)) || (val.cliente.estado.toLocaleLowerCase().includes(args)) || (val.cliente.cidade.toLocaleLowerCase().includes(args)) || (val.cliente.nome.includes(args)) || (val.cliente.email.includes(args)) || (val.cliente.contrato.includes(args)) || (val.cliente.numero.includes(args)) || (val.cliente.estado.includes(args)) || (val.cliente.cidade.includes(args)));
      return rVal;
    })

  }
}