import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  EmailForm: FormGroup;

  constructor(public formBuilder: FormBuilder) {
    let EmailRegex = /^[a-zA-Z0-9._]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
    this.EmailForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(EmailRegex)])],
    });
  }

  ngOnInit() {
  }

  senha() {
    var emailAddress = this.EmailForm.value.email;

    var auth = firebase.auth();
    auth.sendPasswordResetEmail(emailAddress).then(function () {

      // Email sent.
    }).catch((error: any) => {

      // An error happened.
    });

  }

}
