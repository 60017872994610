import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { DatabaseService } from './../database.service';

@Component({
  selector: 'app-inversores',
  templateUrl: './inversores.component.html',
  styleUrls: ['./inversores.component.scss']
})
export class InversoresComponent implements OnInit {
  inversores: Inversor[] = [];
  numero_colunas;
  constructor(private router: Router, public dbService: DatabaseService, public angularFirebase: AngularFireDatabase) {
    this.calcularColunas();
  }

  ngOnInit() {
    let inversers: Inversor[] = [];
    this.angularFirebase.database.ref('/Parâmetros').once('value').then(function (snapshot) {//Transformando os inversores em um objeto da classe de inversores

      snapshot.child(`/inversores`).forEach(function (childSnapshot) {
        let nome = childSnapshot.child(`/nome`).val();
        let potencia = childSnapshot.child(`/potencia`).val();
        let inversor = new Inversor(nome, potencia);
        inversers.push(inversor);

      });
    });

    this.inversores = inversers;
    this.inversores = this.dbService.getInversores();//Pegando a lista de inversores do banco de dados
  }

  calcularColunas() {//Calcular a quantidade de colunas dependendo do tamanho da tela
    if (window.innerWidth >= 1100) {
      this.numero_colunas = 3;
    }
    else if (window.innerWidth < 1100 && window.innerWidth >= 750) {
      this.numero_colunas = 2;
    }

    else {
      this.numero_colunas = 1;
    }

  }

  submit() {//Adicionar um novo inversor
    let nome = ((document.getElementById("nome") as HTMLInputElement).value);
    let potencia = ((document.getElementById("potencia") as HTMLInputElement).value);
    let inversor = new Inversor(nome, potencia);
    this.angularFirebase.database.ref("Parâmetros/inversores/" + nome).set(inversor).then(() => {
      swal({
        icon: 'success',
        title: 'Inversor adicionado com sucesso!',
      })

    })
    this.inversores.push(inversor)
  }

  deletar(nome, index) {//Deletar um inversor
    swal({
      icon: 'warning',
      title: 'Deseja deletar ' + nome + '?',
      buttons: {
        sim: {
          text: "Sim"
        },
        nao: {
          text: 'Não'
        }
      }
    }).then((value) => {
      if (value == 'sim') {
        this.dbService.deletarInversor(nome).then(() => {
          this.dbService.getInversores();
          swal({
            icon: 'success',
            title: 'Deletado com sucesso',
          }).then(() => {
            this.router.navigate(['inversores']);
          });

        })

        this.inversores.splice(index, 1);
      }

    });

  }

}
export class Inversor {//Definindo inversor
  constructor(public nome, public potencia) {
  }
}
