import { AngularFireStorage } from 'angularfire2/storage';
import { AuthServiceProvider } from './providers/auth-service/auth-service';
import { environment } from './../environments/environment';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PropostaComponent } from './proposta/proposta.component';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { CadastrarComponent } from './cadastrar/cadastrar.component';
import { AngularFireAuth, AngularFireAuthModule } from 'angularfire2/auth';
import { HttpClientModule } from '@angular/common/http';
import { UserServiceProvider, Users } from './providers/user-service/user-service';
import { AnaliseComponent } from './analise/analise.component';
import { HistoricoComponent } from './historico/historico.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { ClienteComponent } from './cliente/cliente.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FilterPipe } from './filter.pipe';
import { ParametrosComponent } from './parametros/parametros.component';
import { NovasenhaComponent } from './novasenha/novasenha.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import * as html2canvas from "html2canvas";
import { AdmimComponent } from './admim/admim.component';
import { UsersService } from './users.service';
import { InversoresComponent } from './inversores/inversores.component'

import { MatGridListModule } from '@angular/material/grid-list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LazyRoutingModule } from './lazy/lazy-routing.module';
import { NgxLoadingModule } from 'ngx-loading';

import { NgxSpinnerModule } from 'ngx-spinner';


const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'proposta', component: PropostaComponent },
  { path: 'cadastrar', component: CadastrarComponent },
  { path: 'analise', component: AnaliseComponent },
  { path: 'historico', component: HistoricoComponent },
  { path: 'parametros', component: ParametrosComponent },
  { path: 'novasenha', component: NovasenhaComponent },
  { path: 'cliente', component: ClienteComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'admim', component: AdmimComponent },
  { path: 'inversores', component: InversoresComponent },
  { path: 'lazy', loadChildren: './lazy/lazy.module#LazyModule' }
];
 
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PropostaComponent,
    CadastrarComponent,
    AnaliseComponent,
    HistoricoComponent,
    ClienteComponent,
    FilterPipe,
    ParametrosComponent,
    NovasenhaComponent,
    ForgotpasswordComponent,
    AdmimComponent,
    InversoresComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(
      appRoutes, { useHash: true }
    ),
    HighchartsChartModule,
    Ng2SearchPipeModule,
    MatGridListModule,
    LazyRoutingModule,
    MatCheckboxModule,
    NgxLoadingModule.forRoot({}),
    NgxSpinnerModule
  ],
  providers: [AuthServiceProvider, UserServiceProvider,
    AngularFireStorage, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }