import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { DatabaseService } from './../database.service';
import { AngularFireDatabase } from 'angularfire2/database';
import { User } from '../providers/user-service/user-service';
import { UsersService } from './../users.service'
import { Users } from './../providers/user-service/user-service'
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-admim',
  templateUrl: './admim.component.html',
  styleUrls: ['./admim.component.scss']
})
export class AdmimComponent implements OnInit {

  usuarios: Users[] = [];
  isOwner: boolean;
  isAdmin: boolean;
  userOwner;
  constructor(public usersService: UsersService, public afa: AngularFireAuth, public database: DatabaseService, public angularFirebase: AngularFireDatabase,
    private router: Router) {

    this.usuarios = this.usersService.usuarios;

  }

  ngOnInit() {
    this.angularFirebase.database.ref('users/' + this.afa.auth.currentUser.uid + '/isOwner').once('value').then(val => {
      this.isOwner = val.val()
    })

  }

  //função para mudar as permissões de um determinado usuário
  mudar(uuid, contador) {
    let a = true;
    let admin: boolean;
    let nome: string;

    this.angularFirebase.database.ref('users/' + uuid + '/isAdmim').once('value').then(val => {
      this.isAdmin = val.val();

      this.angularFirebase.database.ref('users/' + uuid + '/isOwner').once('value').then(val => {
        this.userOwner = val.val();
      }).then(() => {

        if (this.isOwner) {
          this.angularFirebase.database.ref('users/' + uuid + '/name').once('value').then(val => {
            nome = val.val();


            if (!this.userOwner) {
              if (!this.isAdmin) {
                swal({
                  icon: 'success',
                  title: 'Mudar permissão',
                  text: 'Deseja mudar a permissão de ' + nome + ' para administrador?',
                  buttons: {
                    sim: {
                      text: "Sim"
                    },
                    nao: {
                      text: "Não"
                    }
                  },
                }).then((value) => {
                  if (value == 'sim') {
                    this.angularFirebase.database.ref('/users/' + uuid + '/isAdmim').set(true);
                    this.usuarios[contador].admim = true;
                    this.database.getUsers();
                    //location.reload(); //dar refresh na pagina
                  }
                });

              }
              else {
                swal({
                  icon: 'success',
                  title: 'Mudar permissão',
                  text: 'Deseja tirar a permissão de ' + nome + '?',
                  buttons: {
                    sim: {
                      text: "Sim"
                    },
                    nao: {
                      text: "Não"
                    }
                  },
                }).then((value) => {
                  if (value == 'sim') {
                    this.angularFirebase.database.ref('/users/' + uuid + '/isAdmim').set(false);
                    this.usuarios[contador].admim = false;
                    this.database.getUsers();
                  }
                });
              }
            } else {
              swal({
                icon: 'error',
                title: 'Erro',
                text: 'Não é possível mudar as permissões desse usuário',
                buttons: {
                  sim: {
                    text: "Ok"
                  },
                },
              })
            }
          });

        } else {
          swal({
            icon: 'error',
            title: 'Erro',
            text: 'Você não possui permissão',
            buttons: {
              ok: {
                text: "Ok"
              },
            }
          })
        }
      });
    })

  }

}


