import { ParametrosService } from './../parametros.service';
import { Proposta, Tarifas, Cliente, Inversor } from './../proposta';
import { DatabaseService } from './../database.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn, FormArray } from "@angular/forms";
import { Component, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";
import { PropostaService } from '../proposta.service';
import { Router, RouterLink } from '@angular/router';
import { routerNgProbeToken } from '@angular/router/src/router_module';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { elementEnd } from '@angular/core/src/render3';
import { ReactiveFormsModule } from '@angular/forms';
import { InversoresComponent } from '../inversores/inversores.component';
import { Filter } from './../cliente/cliente.component'


@Component({
  selector: 'app-proposta',
  templateUrl: './proposta.component.html',
  styleUrls: ['./proposta.component.scss']
})
export class PropostaComponent implements OnInit {

  public dadosForm: FormGroup;
  public names: string[];
  public grupos: string[];
  public estados: string[];
  public cidades = [];
  public firebase;
  public number;
  public inversores: Inversor[] = [];

  public autocomplete = false;
  filteredCities: string[];
  public currentFocus = 0;
  public cidadeSelecionada: string;

  public icms;
  public concessionaria;

  public tipo;
  isAdmim: boolean;
  items: FormArray;

  filter: Filter;
  potencia_padrao;

  constructor(
    public formBuilder: FormBuilder,
    public dbService: DatabaseService,
    public propostaService: PropostaService,
    public afa: AngularFireAuth,
    private router: Router,
    public angularFirebase: AngularFireDatabase,
    public parametrosService: ParametrosService
  ) {
    this.filter = new Filter();

  }


  ngOnInit() {
    this.angularFirebase.database.ref('users/' + this.afa.auth.currentUser.uid + '/isAdmim').once('value').then(val => {
      this.isAdmim = val.val()
    })
    this.grupos = ['Grupo A', 'Grupo B']
    this.names = ['Aluguel', 'Compra', 'Aluguel e Compra'];
    this.estados = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
    this.dadosForm = this.formBuilder.group({
      nome: ['', [Validators.required]],
      telefone: ['', [Validators.required]],
      email: ['', [Validators.required]],
      endereco: ['', [Validators.required]],
      CEP: ['', [Validators.required]],
      tipo: ['', [Validators.required]],
      grupos: ['', [Validators.required]],
      cidade: ['', [Validators.required]],
      estado: ['', [Validators.required]],
      consumo: ['', [Validators.required]],
      potencia: ['', [Validators.required]],

    });
    this.angularFirebase.database.ref('Parâmetros/potencia_padrao').once('value').then(val => { this.potencia_padrao = val.val(), this.dadosForm.get('potencia').setValue(this.potencia_padrao); });

    this.dadosForm.get('potencia').setValue(330);

    this.atualizarCidades();
    this.atualizarTipo();
    this.filtrarCidades();



  }

  atualizarCidades() {
    this.dadosForm.get('estado').valueChanges.subscribe(val => {
      this.cidades = [];
      this.dbService.getCidades(val).then(ret => {
        this.cidades = [];
        if (this.dadosForm.get('estado').value === val) {
          for (let key in ret.val()) {
            this.cidades.push(key);
          }
        }
      });
      this.filteredCities = this.cidades;
      this.currentFocus = -1;
      this.cidadeSelecionada = '';
    });
  }

  atualizarTipo() {
    this.dadosForm.get('tipo').valueChanges.subscribe(val => {
      if (val == "Aluguel") {
        this.tipo = 0;
      } else if (val == "Compra") {
        this.tipo = 1;
      } else {
        this.tipo = 2;
      }
    })
  }

  filtrarCidades() {
    this.dadosForm.get('cidade').valueChanges.subscribe(val => {
      this.filteredCities = [];
      this.currentFocus = -1;
      this.cidadeSelecionada = '';
      this.cidades.forEach(element => {
        if (element.toLowerCase().startsWith(val.toLowerCase())) {
          this.filteredCities.push(element);
        }
      });
      if (val === '') {
        this.autocomplete = false;
      }
      else {
        this.autocomplete = true;
      }
    });
  }
  
  //atualiza as cidades que irão aparecer no auto-complete
  atualizarSugestoes(e: KeyboardEvent) {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      if (this.currentFocus < this.filteredCities.length - 1)
        this.currentFocus++;
      this.cidadeSelecionada = this.filteredCities[this.currentFocus];
    }
    else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (this.currentFocus > 0)
        this.currentFocus--;
      this.cidadeSelecionada = this.filteredCities[this.currentFocus];
    }
    else if (e.key === "Enter") {
      e.preventDefault();
      if (this.currentFocus > -1) {
        this.autocomplete = false;
        this.escolherCidade(this.cidadeSelecionada);
      }
    }
  }
  focus() {
    if (this.dadosForm.get('cidade').value != '')
      this.autocomplete = true;
  }
  esconderSugestoes() {
    this.autocomplete = false;
    this.currentFocus = -1;
    this.cidadeSelecionada = '';
  }
  escolherCidade(cidade) {
    this.dadosForm.get('cidade').setValue(cidade);
    this.esconderSugestoes();

  }
  formatarTelefone(telefone) {

    //(99)9999-9999 - 13dig
    //(99)99999-9999 - 14dig
    telefone = telefone.replace(/[^0-9]+/g, "");
    if (telefone.length > 0) {
      telefone = telefone.substring(-1, 0) + "(" + telefone.substring(0);
    }
    if (telefone.length > 3) {
      telefone = telefone.substring(0, 3) + ")" + telefone.substring(3);
    }
    if (telefone.length == 12) {
      telefone = telefone.substring(0, 8) + "-" + telefone.substring(8);
    } else if (telefone.length >= 13) {
      telefone = telefone.substring(0, 9) + "-" + telefone.substring(9, 13);
    }

    return telefone;
  }



  onSubmit() {




    if (this.dadosForm.get("tipo").value == '') {
      swal({
        icon: 'error',
        title: 'Tipo é obrigatório',
        timer: 4000,
        buttons: {
          okay: {
            text: "OK"
          }
        }
      });

    } else if (this.dadosForm.get("nome").value == '') {
      swal({
        icon: 'error',
        title: 'Nome é obrigatório',
        timer: 4000,
        buttons: {
          okay: {
            text: "OK"
          }
        }
      });
    } else if (this.dadosForm.get("consumo").value == '') {
      swal({
        icon: 'error',
        title: 'Consumo é obrigatório',
        timer: 4000,
        buttons: {
          okay: {
            text: "OK"
          }
        }
      });
    } else if (this.dadosForm.get("cidade").value == '') {
      swal({
        icon: 'error',
        title: 'Cidade é obrigatória',
        timer: 4000,
        buttons: {
          okay: {
            text: "OK"
          }
        }
      });
    } else if (this.dadosForm.get("estado").value == '') {
      swal({
        icon: 'error',
        title: 'Estado é obrigatório',
        timer: 4000,
        buttons: {
          okay: {
            text: "OK"
          }
        }
      });
    } else {


      let tarifas = new Tarifas();
      let proposta = new Proposta();

      let tipo = this.dadosForm.get("tipo").value;
      let grupo = this.dadosForm.get("grupos").value;
      let telefone: string;
      telefone = this.formatarTelefone(this.dadosForm.get('telefone').value);


      let cliente = new Cliente(this.dadosForm.get('nome').value, this.dadosForm.get('email').value, telefone, this.dadosForm.get('cidade').value, this.dadosForm.get('estado').value, "", "Proposta Enviada", this.dadosForm.get('endereco').value, this.dadosForm.get('CEP').value);

      let consumo = this.dadosForm.get('consumo').value;

      if (this)
        this.dbService.getIrradiacao(this.dadosForm.get('estado').value, this.dadosForm.get('cidade').value).then(vec => {
          let irradiacao = vec.val();


          this.dbService.getTarifa(this.dadosForm.get('estado').value).then(val => {
            this.dbService.getTarifaConcessionaria(this.dadosForm.get('estado').value, this.dadosForm.get('cidade').value).then(val2 => {
              tarifas.calcularTarifas(val.val(), val2.val());
              proposta.gerarProposta(grupo, tipo, cliente, consumo, irradiacao, tarifas, this.parametrosService, 0, null, this.dadosForm.get('potencia').value, null, 0);

              this.propostaService.proposta = proposta;
              this.router.navigate(['analise']);
            }).catch((error: any) => {
              swal({
                icon: 'error',
                title: 'Preencha corretamente',
                text: 'Todos os campos são obrigatórios',
                timer: 4000,
                buttons: {
                  okay: {
                    text: "OK"
                  }
                }
              });
            });
          }).catch((error: any) => {
          });
        }).catch((error: any) => {
        });
    }
  }

  logout() {
    this.afa.auth.signOut().then(() => {
      this.router.navigate(['login'])
    }).catch((error) => {
    })
  }

}

