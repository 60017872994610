import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthServiceProvider } from '../providers/auth-service/auth-service';
import { AngularFireAuth } from 'angularfire2/auth';
import { UserServiceProvider, User } from '../providers/user-service/user-service';
import { Router, RouterLink } from '@angular/router';
import { PropostaService } from './../proposta.service';
import { DatabaseService } from './../database.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  LoginForm: FormGroup;



  constructor(
    public formBuilder: FormBuilder,
    public afa: AngularFireAuth,
    public userService: UserServiceProvider,
    public authservice: AuthServiceProvider,
    private router: Router,
    public database: DatabaseService,
    public propostasService: PropostaService
  ) {
    let EmailRegex = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    this.LoginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(EmailRegex)])],
      senha: ['', [Validators.required, Validators.minLength(6)]]
    });
  }
  ngOnInit() {
  }
  onSubmit(): void {


    this.authservice.signinWithEmail(this.LoginForm.value)
      .then((isLogged: boolean) => {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
        });
        this.router.navigate(['proposta']);


      }).catch((error: any) => {
        if (error == 'There is no user record corresponding to this identifier. The user may have been deleted.') {
          swal({
            icon: 'error',
            title: 'Usuário não cadastrado',
          });
        }
        if (error == 'The password is invalid or the user does not have a password.') {
          swal({
            icon: 'error',
            title: 'Senha incorreta',
          });
        }
        if (error == 'A network error (such as timeout, interrupted connection or unreachable host) has occurred.') {
          swal({
            icon: 'error',
            title: 'Erro de conexão',
          });
        }
        if (error == 'The email address is badly formatted.') {
          swal({
            icon: 'error',
            title: 'Email com formato inválido!',
          });
        }

      });

  }
}