import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireObject, AngularFireDatabase } from 'angularfire2/database';
import { BaseServiceProvider } from '../base-service/base-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class UserServiceProvider extends BaseServiceProvider {

  constructor(
    public af: AngularFireAuth,
    public db: AngularFireDatabase,
    public http: HttpClient,
    public angularFirebase: AngularFireDatabase,
  ) {
    super();
  }

  getUser(uuid: string) {
    return this.db.database.ref(`/users/${uuid}`).once('value');
  }
  createUser(user: User, uuid: string): Promise<void> {

    return this.db.object(`/users/${uuid}`)
      .set(user)
      .catch(this.handlePromiseError);


  }
  setUuid(uuid: string) {
    this.db.database.ref(`/users/${uuid}/uuid`).set(uuid);
  }
  change(user: User, uuid: string): Promise<void> {
    return this.db.object(`/users/${uuid}`).set(user).catch(this.handlePromiseError);
  }
}

export class User {

  constructor(
    public name: string,
    public email: string,
    public isAdmim: boolean,
    public isOwner: boolean
  ) { }
}

export class Users {
  nome: string;
  email: string;
  uuid: string;
  admim: boolean;
  owner: boolean;
  contador: number;
  constructor() { }
}