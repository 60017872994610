import { PropostaService } from '../proposta.service';
import { Proposta } from '../proposta';
import { DatabaseService } from './../database.service';
import { AngularFireDatabase } from 'angularfire2/database';
import { Router, RouterLink } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { chart } from 'highcharts';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import * as jspdf from 'jspdf';
import * as html2canvas from 'html2canvas';
import { AngularFireStorage } from 'angularfire2/storage';
import { NovasenhaComponent } from '../novasenha/novasenha.component';
import { ParametrosService } from './../parametros.service';
import { NgxSpinnerService } from 'ngx-spinner';

Highcharts.setOptions({
    plotOptions: {
        line: {
            marker: {
                enabled: true
            },
        }
    },
    chart: {
        backgroundColor: {
            /*linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
            stops: [
                [0, '#403f3b'],
                [1, '#403f3b']
            ]*/
        },
        style: {
            //fontFamily: '\'Unica One\', sans-serif'
            fontFamily: 'Montserrat, Montserrat-light'
        },
        plotBorderColor: '#007140',
        zoomType: 'x',

    }
});

@Component({
    selector: 'app-cliente',
    templateUrl: './cliente.component.html',
    styleUrls: ['./cliente.component.scss']

})
export class ClienteComponent implements OnInit {

    //Utilizando a biblioteca highcharts montamos os gráficos
    @ViewChild('economiaChartTarget') economiaChartTarget: ElementRef;
    @ViewChild('economiavendaChartTarget') economiavendaChartTarget: ElementRef;
    @ViewChild('contaNovaChartTarget') contaNovaChartTarget: ElementRef;
    @ViewChild('producaoChartTarget') producaoChartTarget: ElementRef;
    @ViewChild('economiaChartTarget_hidden') economiaChartTarget_hidden: ElementRef;
    @ViewChild('economiavendaChartTarget_hidden') economiavendaChartTarget_hidden: ElementRef;
    @ViewChild('contaNovaChartTarget_hidden') contaNovaChartTarget_hidden: ElementRef;
    @ViewChild('producaoChartTarget_hidden') producaoChartTarget_hidden: ElementRef;
    options: Highcharts.Options;
    options2: Highcharts.Options;
    options3: Highcharts.Options;
    options_screen: Highcharts.Options;
    options_screen2: Highcharts.Options;
    options_screen3: Highcharts.Options;
    economiaChart: Highcharts.ChartObject;
    economiavendaChart: Highcharts.ChartObject;
    contaNovaChart: Highcharts.ChartObject;
    producaoChart: Highcharts.ChartObject;
    economiaChart_hidden: Highcharts.ChartObject;
    economiavendaChart_hidden: Highcharts.ChartObject;
    contaNovaChart_hidden: Highcharts.ChartObject;
    producaoChart_hidden: Highcharts.ChartObject;


    isAdmim: boolean;
    proposta: Proposta;
    filter: Filter;
    valor_aluguel;
    valor_compra;
    tipo;
    contract;
    pdf_a;
    pdf_v;
    data;
    imagem;
    imagem2;
    imagem3;
    imagem4;
    nr_inversores;
    nome_inversores;
    graphs_loaded: number;
    loading: boolean = true;

    constructor(public storage: AngularFireStorage, public http: HttpClient, public afa: AngularFireAuth,
        private router: Router, public propostaService: PropostaService, public proposta2: PropostaService,
        public databaseService: DatabaseService, public angularFirebase: AngularFireDatabase, public parametros: ParametrosService, private spinner: NgxSpinnerService) {
        this.angularFirebase.database.ref('propostas/' + this.propostaService.proposta.cliente.numero + '/timestamp').once('value').then(val => {
            let data = new Date(val.val());
            let meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
            this.data = data.getDate() + ' de ' + meses[data.getMonth()] + ' de ' + data.getFullYear();
        });
        console.log(this.propostaService);
    }


    ngOnInit() {
        this.graphs_loaded = 0;
        this.filter = new Filter();
        let name: string;
        this.proposta = this.propostaService.proposta;
        this.contract = this.proposta.cliente.contrato;
        this.valor_compra = this.filter.filter(parseFloat(this.proposta.valor_total));
        this.tipo = this.proposta.tipo;
        this.valor_aluguel = this.filter.filter(this.proposta.valor_aluguel);
        this.angularFirebase.database.ref('users/' + this.afa.auth.currentUser.uid + '/isAdmim').once('value').then(val => {
            this.isAdmim = val.val()
        })

        //Definindo as configurações de cada gráfico
        this.options = {//Gráfico na tela cliente Fluxo de caixa
            colors: ['#007140'],
            chart: {
                type: 'column',
                style: {
                    fontFamily: 'Montserrat, Montserrat-light'
                },
                plotBorderColor: '#007140'
            },
            title: {
                text: null
            },
            subtitle: {
                text: null
            },
            xAxis: {
                gridLineColor: '#707073',
                lineColor: '#707073',
                minorGridLineColor: '#505053',
                tickColor: '#707073',
                title: {
                    text: '',
                    style: {
                        color: '#A0A0A3'

                    }
                }
            },
            yAxis: {
                gridLineColor: '#000000',
                labels: {
                    style: {
                        color: '#000000'
                    },
                    format: '{value:.0f}'
                },
                lineColor: '#000000',
                minorGridLineColor: '#505053',
                tickColor: '#000000',
                tickWidth: 1,
                title: {
                    text: null
                }
            },
            tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                style: {
                    color: '#F0F0F0'
                },
                //pointFormat: '{series.name}: <b>{point.y:,.2f}</b><br/>',
                formatter: function () {

                    return this.point.series.name + ': <b>' + 'R$' + Highcharts.numberFormat(this.point.y, 2, ',', '.');
                }
            },
            plotOptions: {
                column: {
                    pointStart: 1,
                },
                boxplot: {
                    fillColor: '#505053'
                },
                errorbar: {
                    color: 'white'
                }
            },
            legend: {
                itemStyle: {
                    color: '#000000'
                },
                itemHoverStyle: {
                    color: '#FFF'
                },
                itemHiddenStyle: {
                    color: '#007140'
                }
            },
            credits: {
                enabled: false
            },
            labels: {
                style: {
                    color: '#707073'
                }
            },

            drilldown: {
                activeAxisLabelStyle: {
                    color: '#F0F0F3'
                },
                activeDataLabelStyle: {
                    color: '#F0F0F3'
                }
            },

            navigation: {
                buttonOptions: {
                    symbolStroke: '#DDDDDD',
                    theme: {
                        fill: '#505053'
                    }
                }
            }
        };
        this.options2 = {//Gráfico na tela cliente Produção de energia
            colors: ['#007140'],
            chart: {
                type: 'column',
                style: {
                    fontFamily: 'Montserrat, Montserrat-light'
                },
                plotBorderColor: '#007140'
            },
            title: {
                text: null
            },
            subtitle: {
                text: null
            },
            xAxis: {
                gridLineColor: '#707073',
                lineColor: '#707073',
                minorGridLineColor: '#505053',
                tickColor: '#707073',
                title: {
                    text: '',
                    style: {
                        color: '#A0A0A3'

                    }
                },
                categories: ['', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            },
            yAxis: {
                gridLineColor: '#000000',
                labels: {
                    style: {
                        color: '#000000'
                    },
                    format: '{value:.0f}'
                },
                lineColor: '#000000',
                minorGridLineColor: '#505053',
                tickColor: '#000000',
                tickWidth: 1,
                title: {
                    text: null
                }
            },
            tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                style: {
                    color: '#F0F0F0'

                },

                //pointFormat: '{series.name}: <b>{point.y:,.2f}</b><br/>',
                formatter: function () {

                    return this.point.series.name + ': <b>' + Highcharts.numberFormat(this.point.y, 0, ',', '.');
                }
            },
            plotOptions: {
                column: {
                    pointStart: 1,
                },
                boxplot: {
                    fillColor: '#505053'
                },
                errorbar: {
                    color: 'white'
                }
            },
            legend: {
                itemStyle: {
                    color: '#000000'
                },
                itemHoverStyle: {
                    color: '#FFF'
                },
                itemHiddenStyle: {
                    color: '#007140'
                }
            },
            credits: {
                enabled: false
            },
            labels: {
                style: {
                    color: '#707073'
                }
            },

            drilldown: {
                activeAxisLabelStyle: {
                    color: '#F0F0F3'
                },
                activeDataLabelStyle: {
                    color: '#F0F0F3'
                }
            },

            navigation: {
                buttonOptions: {
                    symbolStroke: '#DDDDDD',
                    theme: {
                        fill: '#505053'
                    }
                }
            }
        };
        this.options3 = {//Gráfico na tela cliente Economia
            colors: ['#007140'],
            chart: {
                type: 'column',
                style: {
                    fontFamily: 'Montserrat, Montserrat-light'
                },
                plotBorderColor: '#007140'
            },
            title: {
                text: null
            },
            subtitle: {
                text: null
            },
            xAxis: {
                gridLineColor: '#707073',
                lineColor: '#707073',
                minorGridLineColor: '#505053',
                tickColor: '#707073',
                title: {
                    text: '',
                    style: {
                        color: '#A0A0A3'

                    }
                },
                categories: ['', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            },
            yAxis: {
                gridLineColor: '#000000',
                labels: {
                    style: {
                        color: '#000000'
                    },
                    format: '{value:.0f}'
                },
                lineColor: '#000000',
                minorGridLineColor: '#505053',
                tickColor: '#000000',
                tickWidth: 1,
                title: {
                    text: null
                }
            },
            tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                style: {
                    color: '#F0F0F0'
                },
                //pointFormat: '{series.name}: <b>{point.y:,.2f}</b><br/>',
                formatter: function () {

                    return this.point.series.name + ': <b>' + 'R$' + Highcharts.numberFormat(this.point.y, 2, ',', '.');
                }
            },
            plotOptions: {
                column: {
                    pointStart: 1,
                },
                boxplot: {
                    fillColor: '#505053'
                },
                errorbar: {
                    color: 'white'
                }
            },
            legend: {
                itemStyle: {
                    color: '#000000'
                },
                itemHoverStyle: {
                    color: '#FFF'
                },
                itemHiddenStyle: {
                    color: '#007140'
                }
            },
            credits: {
                enabled: false
            },
            labels: {
                style: {
                    color: '#707073'
                }
            },

            drilldown: {
                activeAxisLabelStyle: {
                    color: '#F0F0F3'
                },
                activeDataLabelStyle: {
                    color: '#F0F0F3'
                }
            },

            navigation: {
                buttonOptions: {
                    symbolStroke: '#DDDDDD',
                    theme: {
                        fill: '#505053'
                    }
                }
            }
        };
        let self = this;
        this.options_screen = {//Gráfico no pdf Produção de energia
            colors: ['#FFF9CF'],
            chart: {
                events: {
                    load: function () {
                        self.graphs_loaded += 1;
                        if ((self.proposta.tipo == "Compra" || self.proposta.tipo == "Aluguel e Compra") && self.graphs_loaded == 3 || self.proposta.tipo == "Aluguel" && self.graphs_loaded == 2) {
                            self.gerarGrafico()
                        }
                    }
                },
                type: 'column',
                style: {
                    fontFamily: 'Montserrat, Montserrat-light'
                },
                plotBorderColor: '#007140'
            },
            title: {
                text: null
            },
            subtitle: {
                text: null
            },
            xAxis: {
                gridLineColor: '#707073',
                lineColor: '#707073',
                minorGridLineColor: '#505053',
                tickColor: '#707073',
                tickLength: 0,
                title: {
                    text: '',
                    style: {
                        color: '#A0A0A3'

                    }
                },
                categories: ['', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            },
            yAxis: {
                gridLineColor: '#000000',
                title: {
                    text: null
                },
                labels: {
                    style: {
                        color: '#000000'
                    },
                    format: '{value:.0f}'
                },
                lineColor: '#000000',
                minorGridLineColor: '#505053',
                minorGridLineWidth: 0,
                tickColor: '#000000',
                tickWidth: 1,
                gridLineWidth: 0.1
            },
            tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                style: {
                    color: '#F0F0F0'
                },
                //pointFormat: '{series.name}: <b>{point.y:,.2f}</b><br/>',
                formatter: function () {

                    return this.point.series.name + ': <b>' + Highcharts.numberFormat(this.point.y, 2, ',', '.');
                }
            },
            plotOptions: {
                column: {
                    animation: false,
                    pointStart: 1,
                    borderColor: '#000000',
                    borderWidth: 1,
                    dataLabels: {
                        color: '#000000',
                        enabled: true,
                        crop: false,
                        overflow: 'none',
                        style: {
                            fontSize: 12 + 'px'
                        },
                        formatter: function () {
                            return Highcharts.numberFormat(this.y, 0);
                        }
                    },

                },

                boxplot: {
                    fillColor: '#505053'
                },
                errorbar: {
                    color: 'white'
                }

            },
            legend: {
                itemStyle: {
                    color: '#000000'
                },
                itemHoverStyle: {
                    color: '#FFF'
                },
                itemHiddenStyle: {
                    color: '#007140'
                }
            },
            credits: {
                enabled: false
            },
            /*labels: {
                style: {
                    color: '#707073'
                }
            },*/

            drilldown: {
                activeAxisLabelStyle: {
                    color: '#F0F0F3'
                },
                activeDataLabelStyle: {
                    color: '#F0F0F3'
                }
            },

            navigation: {
                buttonOptions: {
                    symbolStroke: '#DDDDDD',
                    theme: {
                        fill: '#505053'
                    }
                }
            }
        };

        this.options_screen2 = {//gráfico no pdf fluxo de caixa
            colors: ['#FFF9CF'],
            chart: {
                events: {
                    load: function () {
                        self.graphs_loaded += 1;
                        if ((self.proposta.tipo == "Compra" || self.proposta.tipo == "Aluguel e Compra") && self.graphs_loaded == 3 || self.proposta.tipo == "Aluguel" && self.graphs_loaded == 2) {
                            self.gerarGrafico()
                        }
                    }
                },
                type: 'column',
                style: {
                    fontFamily: 'Montserrat, Montserrat-light'
                },
                plotBorderColor: '#007140'
            },
            title: {
                text: null
            },
            subtitle: {
                text: null
            },
            xAxis: {
                gridLineColor: '#707073',
                lineColor: '#707073',
                minorGridLineColor: '#505053',
                tickColor: '#707073',
                title: {
                    text: '',
                    style: {
                        color: '#A0A0A3'

                    }
                },
            },
            yAxis: {
                gridLineColor: '#000000',
                labels: {
                    style: {
                        color: '#000000'
                    },
                    format: '{value:.2f}'
                },
                lineColor: '#000000',
                minorGridLineColor: '#505053',
                tickColor: '#000000',
                tickWidth: 1,
                title: {
                    text: null
                }
            },
            tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                style: {
                    color: '#F0F0F0'
                },
                //pointFormat: '{series.name}: <b>{point.y:,.2f}</b><br/>',
                formatter: function () {

                    return this.point.series.name + ': <b>' + Highcharts.numberFormat(this.point.y, 2, ',', '.');
                }
            },
            plotOptions: {
                column: {
                    animation: false,
                    pointStart: 1,
                    borderColor: '#000000',
                    borderWidth: 1,
                },

                boxplot: {
                    fillColor: '#505053'
                },
                errorbar: {
                    color: 'white'
                }

            },
            legend: {
                itemStyle: {
                    color: '#000000'
                },
                itemHoverStyle: {
                    color: '#FFF'
                },
                itemHiddenStyle: {
                    color: '#007140'
                }
            },
            credits: {
                enabled: false
            },
            labels: {
                style: {
                    color: '#707073'
                }
            },

            drilldown: {
                activeAxisLabelStyle: {
                    color: '#F0F0F3'
                },
                activeDataLabelStyle: {
                    color: '#F0F0F3'
                }
            },

            navigation: {
                buttonOptions: {
                    symbolStroke: '#DDDDDD',
                    theme: {
                        fill: '#505053'
                    }
                }
            }
        };
        this.options_screen3 = {//gráfico no pdf economia
            colors: ['#FFF9CF'],
            chart: {
                events: {
                    load: function () {
                        self.graphs_loaded += 1;
                        if ((self.proposta.tipo == "Compra" || self.proposta.tipo == "Aluguel e Compra") && self.graphs_loaded == 3 || self.proposta.tipo == "Aluguel" && self.graphs_loaded == 2) {
                            self.gerarGrafico()
                        }
                    }
                },
                type: 'column',
                style: {
                    fontFamily: 'Montserrat, Montserrat-light'
                },
                plotBorderColor: '#007140'
            },
            title: {
                text: null
            },
            subtitle: {
                text: null
            },
            xAxis: {
                gridLineColor: '#707073',
                lineColor: '#707073',
                minorGridLineColor: '#505053',
                tickColor: '#707073',
                tickLength: 0,
                title: {
                    text: '',
                    style: {
                        color: '#A0A0A3'

                    }
                },
                categories: ['', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            },
            yAxis: {
                gridLineColor: '#000000',
                title: {
                    text: null
                },
                labels: {
                    style: {
                        color: '#000000'
                    },
                    format: '{value:.2f}'
                },
                lineColor: '#000000',
                minorGridLineColor: '#505053',
                minorGridLineWidth: 0,
                tickColor: '#000000',
                tickWidth: 1,
                gridLineWidth: 0.1
            },
            tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                style: {
                    color: '#F0F0F0'
                },
                //pointFormat: '{series.name}: <b>{point.y:,.2f}</b><br/>',
                formatter: function () {

                    return this.point.series.name + ': <b>' + Highcharts.numberFormat(this.point.y, 2, ',', '.');
                }
            },
            plotOptions: {
                column: {
                    animation: false,
                    pointStart: 1,
                    borderColor: '#000000',
                    borderWidth: 1,
                    dataLabels: {
                        color: '#000000',
                        enabled: true,
                        crop: false,
                        overflow: 'none',
                        style: {
                            fontSize: 12 + 'px'
                        },
                        formatter: function () {
                            return Highcharts.numberFormat(this.point.y, 2, ',', '.');
                        }
                    },

                },

                boxplot: {
                    fillColor: '#505053'
                },
                errorbar: {
                    color: 'white'
                }

            },
            legend: {
                itemStyle: {
                    color: '#000000'
                },
                itemHoverStyle: {
                    color: '#FFF'
                },
                itemHiddenStyle: {
                    color: '#007140'
                }
            },
            credits: {
                enabled: false
            },
            /*labels: {
                style: {
                    color: '#707073'
                }
            },*/

            drilldown: {
                activeAxisLabelStyle: {
                    color: '#F0F0F3'
                },
                activeDataLabelStyle: {
                    color: '#F0F0F3'
                }
            },

            navigation: {
                buttonOptions: {
                    symbolStroke: '#DDDDDD',
                    theme: {
                        fill: '#505053'
                    }
                }
            }
        };
        this.nome_inversores = this.proposta.inversores[0].numero + ' ' + this.proposta.inversores[0].nome;



    }


    ngAfterViewInit() {
        //Iniciando os g´raficos
        if (this.proposta.tipo != "Compra") {
            this.economiaChart = chart(this.economiaChartTarget.nativeElement, this.options3);
            this.economiaChart.addSeries({ name: "Economia de Aluguel", data: this.propostaService.proposta.economia_aluguel });
        }

        if (this.proposta.tipo == 'Compra' || this.proposta.tipo == 'Aluguel e Compra') {
            this.contaNovaChart = chart(this.contaNovaChartTarget.nativeElement, this.options);
            let fluxo_caixa = this.propostaService.proposta.calcularFluxoCaixa(20)
            this.contaNovaChart.addSeries({ name: "Fluxo de caixa acumulado", data: fluxo_caixa });


            this.economiavendaChart = chart(this.economiavendaChartTarget.nativeElement, this.options3);
            this.economiavendaChart.addSeries({ name: "Economia de Compra", data: this.propostaService.proposta.economia_venda });
        }


        this.producaoChart = chart(this.producaoChartTarget.nativeElement, this.options2);
        this.producaoChart.addSeries({ name: "Produção mensal de energia", data: this.propostaService.proposta.producao_fv });

        if (this.proposta.tipo != 'Aluguel') {
            let fluxo_caixa = this.propostaService.proposta.calcularFluxoCaixa(20)
            this.contaNovaChart_hidden = chart(this.contaNovaChartTarget_hidden.nativeElement, this.options_screen2);
            this.contaNovaChart_hidden.addSeries({ name: "Fluxo de caixa acumulado", data: fluxo_caixa });

            this.economiavendaChart_hidden = chart(this.economiavendaChartTarget_hidden.nativeElement, this.options_screen3);
            this.economiavendaChart_hidden.addSeries({ name: "Economia de Compra", data: this.propostaService.proposta.economia_venda });
        }
        if (this.proposta.tipo != "Compra") {
            this.economiaChart_hidden = chart(this.economiaChartTarget_hidden.nativeElement, this.options_screen3);
            this.economiaChart_hidden.addSeries({ name: "Economia de Aluguel", data: this.propostaService.proposta.economia_aluguel });
        }
        this.producaoChart_hidden = chart(this.producaoChartTarget_hidden.nativeElement, this.options_screen);
        this.producaoChart_hidden.addSeries({ name: "Produção mensal de energia", data: this.propostaService.proposta.producao_fv });



    }
    Deletar() {
        //Deletar a proposta
        if (this.isAdmim) {
            this.databaseService.deletarProposta(this.proposta.cliente.numero).then(() => {
                this.databaseService.historicoPropostas();
                swal({
                    icon: 'success',
                    title: 'Deletado com sucesso',
                });

                this.router.navigate(['historico']);


            })
        }else{
            swal({
                icon: 'error',
                title: 'Usuário sem permissão',
            });
        }



    }

    contrato(param) {
        //Editar a situação do contrato
        this.databaseService.mudarContrato(this.proposta.cliente.numero, param).then(() => {
            this.contract = param;
            this.databaseService.historicoPropostas();
            swal({
                icon: 'success',
                title: 'Editado com sucesso',
            });

        }

        )
    }

    //Criando o pdf de aluguel
    public aluguel() {
        //this.gerarGrafico();
        this.pdf_a = null;

        var image1 = new Image();
        var image2 = new Image();
        var image3 = new Image();
        var image4 = new Image();
        var image5 = new Image();
        var image6 = new Image();
        var image7 = new Image();
        var image8 = new Image();
        var image9 = new Image();
        var image10 = new Image();
        var image11 = new Image();
        var image11 = new Image();
        var image12 = new Image();
        var image13 = new Image();
        var image14 = new Image();
        var image15 = new Image();

        image1.src = '../../assets/aluguel/Aluguel-1.jpg';
        image2.src = '../../assets/aluguel/Aluguel-2.jpg';
        image3.src = '../../assets/aluguel/Aluguel-3.jpg';
        image4.src = '../../assets/aluguel/Aluguel-4.jpg';
        image5.src = '../../assets/aluguel/Aluguel-5.jpg';
        image6.src = '../../assets/aluguel/Aluguel-6.jpg';
        image7.src = '../../assets/aluguel/Aluguel-7.jpg';
        image8.src = '../../assets/aluguel/Aluguel-8.jpg';
        image9.src = '../../assets/aluguel/Aluguel-9.jpg';
        image10.src = '../../assets/aluguel/Aluguel-10.jpg';
        image11.src = '../../assets/aluguel/Aluguel-11.jpg';
        image12.src = '../../assets/aluguel/Aluguel-12.jpg';
        image13.src = '../../assets/aluguel/Aluguel-13.jpg';
        image14.src = '../../assets/aluguel/Aluguel-14.jpg';
        image15.src = '../../assets/aluguel/Aluguel-15.jpg';

        this.pdf_a = new jspdf('p', 'pt', [1080, 1920]);

        this.http.get('../../assets/fonts/montserrat.txt', { responseType: 'text' })
            .subscribe(data => {
                this.pdf_a.addFileToVFS("Montserrat-Regular.ttf", data);
                this.pdf_a.addFont("Montserrat-Regular.ttf", "Montserrat", "Bold");
                this.pdf_a.setFont("Montserrat", "Bold");

                //page 1 - OK
                this.pdf_a.addImage(image1, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_a.setFontSize(40);
                this.pdf_a.text(170, 1314, this.proposta.cliente.nome); //Nome
                this.pdf_a.text(217, 1359, this.proposta.cliente.endereco); //Endereço
                this.pdf_a.text(19, 1404, this.proposta.cliente.cidade+'/'+this.proposta.cliente.estado); //Cidade/estado 
                let tempo = this.data + '';
                this.pdf_a.text(132, 1448, tempo); //Data
                this.pdf_a.text(252, 1492, this.proposta.cliente.numero); //Nº Projeto
                this.pdf_a.addPage();

                //page 2 - OK
                this.pdf_a.addImage(image2, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_a.addPage();

                //page 3 - OK
                this.pdf_a.addImage(image3, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_a.addPage();

                //page 4 - OK
                this.pdf_a.addImage(image4, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_a.addPage();

                //page 5 - OK
                this.pdf_a.addImage(image5, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_a.addPage();

                //page 6 - OK
                this.pdf_a.addImage(image6, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_a.addPage();

                //page 7 - OK
                this.pdf_a.addImage(image7, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_a.addPage();

                //page 8 - OK
                this.pdf_a.addImage(image8, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_a.setFontSize(38);
                this.pdf_a.setTextColor(255, 255, 255);
                this.pdf_a.text(160, 910, 'R$ ' + this.filter.filter(this.proposta.conta_antiga)); //conta de luz antiga

                let conta_anual = 0;
                this.proposta.conta_nova.forEach(function (value) {
                    conta_anual += value;
                });
                let conta_nova = conta_anual / 12;

                this.pdf_a.text(733, 1288, 'R$ ' + this.filter.filter(conta_nova)); //conta de luz nova
                this.pdf_a.setTextColor(0, 0, 0);
                let aluguel = this.proposta.valor_aluguel;
                this.pdf_a.text(733, 1010, 'R$ ' + this.filter.filter(this.proposta.valor_aluguel)); //aluguel solar21
                let economia_anual = 0;
                this.proposta.economia_aluguel.forEach(function (value) {
                    economia_anual += value;
                });
                let economia_mensal = economia_anual / 12;
                this.pdf_a.text(733, 665, 'R$ ' + this.filter.filter(economia_mensal)); //economia mensal
                this.pdf_a.addPage();

                //page 9 - OK
                this.pdf_a.addImage(image9, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_a.addPage();

                //page 10 - OK
                this.pdf_a.addImage(image10, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_a.addPage();

                //page 11 - OK
                this.pdf_a.addImage(image11, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_a.setFontSize(55);
                var potencia_real = parseFloat(
                    this.proposta.potencia_real.replace(/,/g, ".")
                ).toFixed(2).replace('.', ',');
                this.pdf_a.text(100, 538, potencia_real + ' kWp'); //Potencia do gerador
                let modulos = this.proposta.n_modulos + "";
                this.pdf_a.text(100, 765, modulos+' de '+ this.proposta.potencia_placa + 'kW cada'); //Quantidade de modulos
                this.pdf_a.text(100, 963, this.nome_inversores); //Quantidade de inversores
                let producao_anual = 0;
                this.proposta.producao_fv.forEach(function (value) {
                    producao_anual += value;
                });
                let production = producao_anual / 12;
                let producao_co = production * 12 * 0.53 / 1000

                this.pdf_a.text(100, 1185, this.filter.filtro(producao_anual) + ' kWh/ano'); //Energia produzida

                this.pdf_a.text(100, 1395, this.filter.filter(producao_co) + ' ton de CO2/ano'); //Emissoes de CO2 evitadas
                let area_unitaria;
                if (this.proposta.potencia_placa < 390) {
                    area_unitaria = 2;
                }
                else { area_unitaria = 2.2 }
                let area = area_unitaria * this.proposta.n_modulos;
                this.pdf_a.text(100, 1574, this.filter.filtro(area) + ' m²'); //Área das placas
                this.pdf_a.addPage();

                //page 12 - OK
                this.pdf_a.addImage(image12, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_a.setFontSize(80);
                this.pdf_a.text(195, 520, 'R$ ' + this.filter.filter(aluguel) + ' / MÊS'); //Aluguel
                this.pdf_a.text(350, 880, 'R$ ' + '0,00'); //Investimento
                this.pdf_a.text(360, 1325, this.proposta.datas.tempo_contrato + ' ANOS'); //Tempo de contrato
                this.pdf_a.addPage();

                //page 13 - OK
                this.pdf_a.addImage(image13, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_a.setFontSize(50);

                let conta_antiga = this.proposta.conta_antiga * 12;
                this.pdf_a.text(730, 460, 'R$ ' + this.filter.filter(conta_antiga)); //Conta sem solar21
                this.pdf_a.text(730, 610, 'R$ ' + this.filter.filter(conta_anual)); //Conta com solar21

                let valor_anual = this.proposta.valor_aluguel * 12;
                economia_anual = conta_antiga - valor_anual - conta_anual;
                this.pdf_a.text(730, 755, 'R$ ' + this.filter.filter(valor_anual)); //Valor anual do aluguelh
                this.pdf_a.text(730, 900, 'R$ ' + this.filter.filter(economia_anual)); //Economia anual
                let conta_ano = conta_antiga - conta_anual;
                let aumento_5anos: number = this.juros(5);
                let economia_5anos = (economia_anual) * aumento_5anos;
                this.pdf_a.text(730, 1035, 'R$ ' + this.filter.filter(economia_5anos));//Economia 5 anos
                this.pdf_a.setFontSize(100);
                this.pdf_a.text(375, 1500, this.filter.filter(Number((economia_anual / conta_antiga) * 100)) + ' %'); //Economia anual media
                this.pdf_a.addPage();

                //page 14
                this.pdf_a.addImage(image14, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_a.addImage(this.imagem3, 'JPEG', 0, 500, 1080, 400) //Medidas para o grafico 1
                this.pdf_a.addImage(this.imagem2, 'JPEG', 0, 1100, 1080, 400);//Medidas para o grafico 2
                this.pdf_a.addPage();

                //page 15 - OK
                this.pdf_a.addImage(image15, 'JPEG', 0, 0, 1080, 1920);

                let name = 'Solar21_' + this.proposta.cliente.numero + '_' + this.proposta.cliente.estado + ' - Proposta de Aluguel - [' + this.proposta.cliente.nome + '] - ' + this.proposta.potencia_real + 'kWp.pdf';
                this.pdf_a.save(name);//Baixando o pdf
                this.spinner.hide();//Parando a tela de "loading"
                ;

            });

    }

    //Criando o aluguel de venda
    public venda() {

        this.pdf_v = null;
        //this.gerarGrafico();
        var image1 = new Image();
        var image2 = new Image();
        var image3 = new Image();
        var image4 = new Image();
        var image5 = new Image();
        var image6 = new Image();
        var image7 = new Image();
        var image8 = new Image();
        var image9 = new Image();
        var image10 = new Image();
        var image11 = new Image();
        var image11 = new Image();
        var image12 = new Image();
        var image13 = new Image();
        var image14 = new Image();
        var image15 = new Image();
        var image16 = new Image();
        var image17 = new Image();
        var image18 = new Image();

        image1.src = '../../assets/compra/Compra-1.jpg';
        image2.src = '../../assets/compra/Compra-2.jpg';
        image3.src = '../../assets/compra/Compra-3.jpg';
        image4.src = '../../assets/compra/Compra-4.jpg';
        image5.src = '../../assets/compra/Compra-5.jpg';
        image6.src = '../../assets/compra/Compra-6.jpg';
        image7.src = '../../assets/compra/Compra-7.jpg';
        image8.src = '../../assets/compra/Compra-8.jpg';
        image9.src = '../../assets/compra/Compra-9.jpg';
        image10.src = '../../assets/compra/Compra-10.jpg';
        image11.src = '../../assets/compra/Compra-11.jpg';
        image12.src = '../../assets/compra/Compra-12.jpg';
        image13.src = '../../assets/compra/Compra-13.jpg';
        image14.src = '../../assets/compra/Compra-14.jpg';
        image15.src = '../../assets/compra/Compra-15.jpg';
        image16.src = '../../assets/compra/Compra-16.jpg';
        image17.src = '../../assets/compra/Compra-17.jpg';
        image18.src = '../../assets/compra/Compra-18.jpg';

        this.pdf_v = new jspdf('p', 'pt', [1080, 1920]);

        this.http.get('../../assets/fonts/montserrat.txt', { responseType: 'text' })
            .subscribe(data => {
                this.pdf_v.addFileToVFS("Montserrat-Regular.ttf", data);
                this.pdf_v.addFont("Montserrat-Regular.ttf", "Montserrat", "Bold");
                this.pdf_v.setFont("Montserrat", "Bold");

                //page 1 - OK
                this.pdf_v.addImage(image1, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_v.setFontSize(40);
                this.pdf_v.text(203, 1286, this.proposta.cliente.nome); //Nome
                this.pdf_v.text(252, 1330, this.proposta.cliente.endereco); //Endereço
                this.pdf_v.text(57, 1375, this.proposta.cliente.cidade+'/'+this.proposta.cliente.estado); //Cidade/estado
                this.pdf_v.text(164, 1418, this.data + ''); //Data
                this.pdf_v.text(283, 1460, this.proposta.cliente.numero); //Nº Projeto
                this.pdf_v.addPage();

                //page 2 - OK
                this.pdf_v.addImage(image2, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_v.addPage();

                //page 3 - OK
                this.pdf_v.addImage(image3, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_v.addPage();

                //page 4 - OK
                this.pdf_v.addImage(image4, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_v.addPage();

                //page 5 - OK
                this.pdf_v.addImage(image5, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_v.addPage();

                //page 6 - OK
                this.pdf_v.addImage(image6, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_v.addPage();

                //page 7 - OK 
                this.pdf_v.addImage(image7, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_v.addPage();

                //page 8 - OK
                this.pdf_v.addImage(image8, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_v.setFontSize(30);
                this.pdf_v.setTextColor(255, 255, 255);
                this.pdf_v.text(250, 720, 'R$ ' + this.filter.filter(this.proposta.conta_antiga)); //conta de luz antiga
                let conta = 0;
                this.proposta.conta_nova.forEach(function (value) {
                    conta += value;
                })
                this.pdf_v.text(670, 995, 'R$ ' + this.filter.filter(conta / 12)); //conta de luz nova
                this.pdf_v.setTextColor(0, 0, 0);
                let economia = 0;
                this.proposta.economia_venda.forEach(function (value) {
                    economia += value;
                })
                this.pdf_v.text(670, 720, 'R$ ' + this.filter.filter(economia / 12)); //economia mensal
                this.pdf_v.addPage();

                //page 9 - OK
                this.pdf_v.addImage(image9, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_v.addPage();

                //page 10 - OK
                this.pdf_v.addImage(image10, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_v.addPage();

                //page 11 - OK
                this.pdf_v.addImage(image11, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_v.setFontSize(55);
                var potencia_real = parseFloat(
                    this.proposta.potencia_real.replace(/,/g, ".")
                ).toFixed(2).replace('.', ',');
                this.pdf_v.text(100, 538, potencia_real + 'kWp'); //Potencia do gerador
                this.pdf_v.text(100, 765, this.proposta.n_modulos + ' de '+ this.proposta.potencia_placa + 'kW cada'); //Quantidade de modulos
                this.pdf_v.text(100, 963, this.nome_inversores); //Quantidade de inversores
                let producao_anual = 0
                this.proposta.producao_fv.forEach(function (value) {
                    producao_anual += value;
                });
                let production = producao_anual / 12;
                let producao_co = production * 12 * 0.53 / 1000
                this.pdf_v.text(100, 1185, this.filter.filtro(producao_anual) + 'kWh/ano'); //Energia produzida
                this.pdf_v.text(100, 1395, this.filter.filter(producao_co) + ' ton de CO2/ano'); //Emissoes de CO2 evitadas
                let area_unitaria;
                if (this.proposta.potencia_placa < 390) {
                    area_unitaria = 2;
                }
                else { area_unitaria = 2.2 }
                let area = area_unitaria * this.proposta.n_modulos;
                this.pdf_v.text(100, 1574, this.filter.filtro(area) + 'm²'); //Área das placas
                this.pdf_v.addPage();

                //page 12 - OK
                this.pdf_v.addImage(image12, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_v.setFontSize(80);

                this.pdf_v.text(100, 525, 'R$ ' + this.filter.filter(this.proposta.valor_total)); //Valor total
                this.pdf_v.text(100, 830, 'R$ ' + this.filter.filter(this.proposta.valor_total * 0.3)); //Valor na assinatura
                this.pdf_v.text(100, 1075, 'R$ ' + this.filter.filter(this.proposta.valor_total * 0.4)); //Valor na chegada
                this.pdf_v.text(100, 1370, 'R$ ' + this.filter.filter(this.proposta.valor_total * 0.3)); //Valor apos instalação
                this.pdf_v.addPage();

                //page 13 - OK
                this.pdf_v.addImage(image13, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_v.setFontSize(60)
                let conta_antiga = this.proposta.conta_antiga * 12;
                this.pdf_v.text(100, 655, 'R$ ' + this.filter.filter(conta_antiga)); //Conta sem solar21
                let conta_anual = 0;
                this.proposta.conta_nova.forEach(function (value) {
                    conta_anual += value;
                });
                let reduction = economia / conta_antiga;
                let percentual_reduction = 100 * reduction;
                this.pdf_v.text(100, 790, 'R$ ' + this.filter.filter(conta_anual)); //Conta com solar21
                this.pdf_v.text(100, 929, 'R$ ' + this.filter.filter(economia)); //Economia 1 ano
                this.pdf_v.text(100, 1065, this.filter.filter(percentual_reduction) + '%'); //Redução dos gastos
                let aumento_5anos: number = this.juros(5)
                let economia_5anos = economia * aumento_5anos;
                this.pdf_v.text(100, 1198, 'R$ ' + this.filter.filter(economia_5anos)); //Economia 5 anos;

                let aumento_10anos: number = this.juros(10)
                let economia_10anos = economia * aumento_10anos;
                this.pdf_v.text(100, 1335, 'R$ ' + this.filter.filter(economia_10anos)); //Economia 10 anos
                this.pdf_v.text(100, 1472, this.filter.filter(this.proposta.payback_simples) + 'anos'); //Payback simples
                let wattpico = parseFloat(this.proposta.valor_total) / (parseFloat(this.proposta.potencia_real) * 1000);
                this.pdf_v.text(100, 1608, 'R$ ' + this.filter.filter(wattpico)); //Preço por wattpico
                this.pdf_v.addPage();

                //page 14 - OK
                this.pdf_v.addImage(image14, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_v.addImage(this.imagem, 'JPEG', 0, 500, 1080, 800);//Medidas para o grafico 1
                this.pdf_v.addPage();

                //page 15 - OK
                this.pdf_v.addImage(image15, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_v.addImage(this.imagem3, 'JPEG', 30, 430, 1020, 470);//Medidas para o grafico 2
                this.pdf_v.addImage(this.imagem4, 'JPEG', 30, 987, 1020, 470);//Medidas para o grafico 3
                this.pdf_v.addPage();

                //page 16 - OK
                this.pdf_v.addImage(image16, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_v.setFontSize(45);
                this.pdf_v.text(220, 670, 'D0');
                this.pdf_v.text(220, 815, 'D0 + 15 DIAS');
                this.pdf_v.text(220, 955, 'D0 + 30 DIAS');
                this.pdf_v.text(220, 1095, 'D0 + 50 DIAS');
                this.pdf_v.text(220, 1242, 'D0 + 55 DIAS');
                this.pdf_v.text(220, 1385, 'D0 + ' + this.proposta.datas.finalizacao_instalacao + ' DIAS');
                this.pdf_v.text(220, 1525, 'D0 + ' + this.proposta.datas.sistema_operante + ' DIAS');
                this.pdf_v.addPage();

                //page 17 - OK
                this.pdf_v.addImage(image17, 'JPEG', 0, 0, 1080, 1920);
                this.pdf_v.setFontSize(60);
                this.pdf_v.text(120, 690, this.parametros.modulo_canadian + ' ANOS');
                this.pdf_v.text(120, 847, this.parametros.inversor_abb + ' ANOS');
                this.pdf_v.text(120, 1010, this.parametros.estrutura_fixacao + ' ANOS');
                this.pdf_v.text(120, 1170, this.parametros.servico_instalacao + ' ANOS');
                this.pdf_v.addPage();

                //page 18 - OK
                this.pdf_v.addImage(image18, 'JPEG', 0, 0, 1080, 1920);
                let name = 'Solar21_' + this.proposta.cliente.numero + '_' + this.proposta.cliente.estado + ' - Proposta de Compra - [' + this.proposta.cliente.nome + '] - ' + this.proposta.potencia_real + 'kWp.pdf';

                this.pdf_v.save(name);//Baixando o pdf
                this.spinner.hide();//Parando a tela de "loading"



            });
    }

    reajustarProposta() {//Serve para ir para a tela de edião da proposta
        this.propostaService.proposta = this.proposta;
        this.propostaService.proposta.proposta_reajuste = this.proposta.cliente.numero;
        this.propostaService.proposta.reajuste = true;
        this.router.navigate(['analise'])
    }
    baixarProposta() {//Baixando e criando o pdf
        this.spinner.show();
        if (this.proposta.tipo == 'Aluguel') {
            this.aluguel();
        }
        else if (this.proposta.tipo == 'Compra') {
            this.venda();
        }
        else {
            this.venda();
            this.aluguel();
        }
    }

    gerarGrafico() {//Essa parte tira um "print" dos gráficos e adicona em uma variável do tipo imagem

        if (this.proposta.tipo != 'Aluguel') {
            var print = document.getElementById('grafico4');
            print.style.display = "block";
            html2canvas(print).then(canvas => {
                this.imagem = canvas.toDataURL('image/png');
            });

            var print4 = document.getElementById('grafico8');
            print4.style.display = "block";
            html2canvas(print4).then(canvas => {
                this.imagem4 = canvas.toDataURL('image/png');
            });
        }
        if (this.proposta.tipo != 'Compra') {
            var print2 = document.getElementById('grafico5');
            print2.style.display = "block";
            html2canvas(print2).then(canvas => {

                this.imagem2 = canvas.toDataURL('image/png')
            });
        }
        var print3 = document.getElementById('grafico6');
        print3.style.display = "block";
        html2canvas(print3).then(canvas => {
            this.imagem3 = canvas.toDataURL('image/png')
        });

    }

    juros(n) {//O aumento nos custos com o tempo
        let aumento = parseFloat(this.proposta.parametros.aumento_distribuidora);
        let resultado: number = (Math.pow(aumento + 1, n) - 1) / (aumento);
        return resultado;
    }





}

export class Filter {//Usando um formatador dos números
    public filter(number) {
        return number.toFixed(2) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
    public filtro(number){
        return number.toFixed(0) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
}