/*
Serviço que busca as informações no banco de dados e integra com o typescript
*/
import { AngularFireDatabase } from 'angularfire2/database';
import { Injectable } from '@angular/core';
import { DataSnapshot } from '@angular/fire/database/interfaces';
import { Proposta, Tarifas, Cliente, Inversor, Datas } from './proposta';
import * as firebase from 'firebase';
import { PropostaService } from './proposta.service';
import { UsersService } from './users.service'

import { Users } from './providers/user-service/user-service';



@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  constructor(public usuariosService: UsersService, public angularFirebase: AngularFireDatabase, public propostasService: PropostaService) {

  }

  public getIrradiacao(estado: string, cidade: string) {
    return this.angularFirebase.database.ref('Estados/' + estado + '/cidades/' + cidade).once('value');
  }
  public getCidades(estado: string): Promise<DataSnapshot> {
    return this.angularFirebase.database.ref('Estados/' + estado + '/cidades/').once('value');
  }

  public getTarifa(estado: string) {
    return this.angularFirebase.database.ref('Estados/' + estado + '/tarifas/').once('value');
  }

  public getTarifaConcessionaria(estado: string, cidade: string) {
    return this.angularFirebase.database.ref('Estados/' + estado + '/cidades/' + cidade + '/distribuidora/').once('value');
  }

  public setTarifaConcessionaria(estado: string, cidade: string, valor: number) {
    return this.angularFirebase.database.ref('Estados/' + estado + '/cidades/' + cidade + '/distribuidora/valor/').set(valor);
  }

  public getParametros() {
    return this.angularFirebase.database.ref('Parâmetros').once('value');
  }

  public novaProposta(proposta: Proposta, nome: string) {
    proposta.timestamp = firebase.database.ServerValue.TIMESTAMP;
    return this.angularFirebase.database.ref('/propostas/' + nome).set(proposta);
  }
  public deletarProposta(nome: string) {
    return this.angularFirebase.database.ref('propostas').child(nome).remove();
  }
  public mudarContrato(nome: string, novo) {
    return this.angularFirebase.database.ref('/propostas/' + nome + '/cliente/contrato').set(novo);
  }
  public deletarInversor(nome: string) {
    return this.angularFirebase.database.ref('Parâmetros/inversores').child(nome).remove();
  }

  public historicoPropostas() {
    let proposta_array: Proposta[] = [];

    this.angularFirebase.database.ref('/').once('value').then(function (snapshot) {

      let propostas = snapshot.child('/propostas').val();

      for (let proposta in propostas) {
        let propose = new Proposta();

        let nome = propostas[proposta]['cliente']['nome'];
        let email = propostas[proposta]['cliente']['email'];
        let telefone = propostas[proposta]['cliente']['telefone'];
        let cidade = propostas[proposta]['cliente']['cidade'];
        let estado = propostas[proposta]['cliente']['estado'];
        let numero = propostas[proposta]['cliente']['numero'];
        let contrato = propostas[proposta]['cliente']['contrato'];
        let endereco = propostas[proposta]['cliente']['endereco'];
        let CEP = propostas[proposta]['cliente']['CEP'];

        let cliente: Cliente;
        cliente = new Cliente(nome, email, telefone, cidade, estado, numero, contrato, endereco, CEP);

        propose.cliente = cliente;

        let datas: Datas;
        datas = new Datas();
        datas.finalizacao_instalacao = propostas[proposta]['datas']['finalizacao_instalacao'];
        datas.sistema_operante = propostas[proposta]['datas']['sistema_operante'];
        datas.tempo_contrato = propostas[proposta]['datas']['tempo_contrato'];
        propose.datas = datas;
        let tarifas: Tarifas;
        tarifas = new Tarifas();
        tarifas.icms = propostas[proposta]['tarifas']['icms'];
        tarifas.pis = propostas[proposta]['tarifas']['pis'];
        tarifas.cofins = propostas[proposta]['tarifas']['cofins'];
        tarifas.tarifa_total = propostas[proposta]['tarifas']['tarifa_total'];
        tarifas.bandeira_tarifaria = propostas[proposta]['tarifas']['bandeira_tarifaria'];
        tarifas.kwh_solar21 = propostas[proposta]['tarifas']['kwh_solar21'];
        tarifas.kwh_concessionaria = propostas[proposta]['tarifas']['kwh_concessionaria'];
        propose.tarifas = tarifas;



        propose.vendedor = propostas[proposta]['vendedor'];
        propose.consumo = propostas[proposta]['consumo'];
        propose.n_modulos = propostas[proposta]['n_modulos'];
        propose.n_modulos_sugerido = propostas[proposta]['n_modulos_sugerido'];
        propose.timestamp = propostas[proposta]['timestamp'];
        let data = new Date(propose.timestamp);
        let meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
        let dataString = data.getDate() + ' de ' + meses[data.getMonth()] + ' de ' + data.getFullYear();
        propose.timestamp = dataString;
        propose.conta_antiga = propostas[proposta]['conta_antiga'];
        propose.economia_aluguel = propostas[proposta]['economia_aluguel'];
        propose.economia_venda = propostas[proposta]['economia_venda'];
        propose.irradiacao = propostas[proposta]['irradiacao'];
        propose.reducao_aluguel = propostas[proposta]['reducao_aluguel'];
        propose.reducao_venda = propostas[proposta]['reducao_venda'];
        propose.conta_nova = propostas[proposta]['conta_nova'];
        propose.producao_fv = propostas[proposta]['producao_fv'];
        propose.tipo = propostas[proposta]['tipo'];
        propose.grupo = propostas[proposta]['grupo'];
        propose.valor_aluguel = propostas[proposta]['valor_aluguel'];
        propose.valor_total = propostas[proposta]['valor_total'];
        propose.parametros = propostas[proposta]['parametros'];
        propose.parametros = propostas[proposta]['parametros'];
        propose.payback_simples = propostas[proposta]['payback_simples'];
        propose.inversores = propostas[proposta]['inversores'];
        propose.potencia_real = propostas[proposta]['potencia_real'];
        propose.potencia_placa = propostas[proposta]['potencia_placa'];
        proposta_array.push(propose);
      }
    });

    this.propostasService.propostas = proposta_array;

  }

  public getUsers() {
    let users_array: Users[] = []

    this.angularFirebase.database.ref('/').once('value').then(function (snapshot) {
      let contador = 0;

      let users = snapshot.child('/users').val();
      for (let i in users) {
        let user = new Users();

        user.nome = users[i]['name'];
        user.email = users[i]['email'];
        user.uuid = users[i]['uuid'];
        user.admim = users[i]['isAdmim'];
        user.owner = users[i]['isOwner'];
        user.contador = contador;

        users_array.push(user);
        contador++;
      }
    });

    this.usuariosService.usuarios = users_array;


  }

  public getInversores() {
    let inversores: Inversor[] = [];
    this.angularFirebase.database.ref('/Parâmetros').once('value').then(function (snapshot) {

      snapshot.child(`/inversores`).forEach(function (childSnapshot) {

        let inversor = new Inversor();
        inversor.nome = childSnapshot.child(`/nome`).val();
        inversor.potencia = childSnapshot.child(`/potencia`).val();
        inversores.push(inversor);

      });
    });

    return inversores;
  }





}


