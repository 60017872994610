import { ParametrosService } from './../parametros.service';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { DatabaseService } from './../database.service';
import { AngularFireDatabase } from 'angularfire2/database';
import { Router, RouterLink } from '@angular/router';
import { Filter } from './../cliente/cliente.component'


@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styleUrls: ['./parametros.component.scss']
})
export class ParametrosComponent implements OnInit {
  area;
  aumento;
  inversor;
  modulo;
  aumento_distribuidora: any;
  perdas_inversor: any;
  eficiencia_modulo: any;
  isAdmim: boolean;
  isOwner: boolean;
  filter;
  potencia_padrao;
  modulo_canadian;
  inversor_abb;
  estrutura_fixacao;
  servico_instalacao;

  constructor(
    public afa: AngularFireAuth, public databaseService: DatabaseService,
    public angularFirebase: AngularFireDatabase, public parametrosService: ParametrosService,
    private router: Router) {


    this.angularFirebase.database.ref('Parâmetros/aumento_distribuidora').once('value').then(val => { this.aumento_distribuidora = val.val() })
    this.angularFirebase.database.ref('Parâmetros/eficiencia_inversor').once('value').then(val => { this.perdas_inversor = val.val() })
    this.angularFirebase.database.ref('Parâmetros/eficiencia_modulo').once('value').then(val => { this.eficiencia_modulo = val.val() });
    this.angularFirebase.database.ref('Parâmetros/potencia_padrao').once('value').then(val => { this.potencia_padrao = val.val() });
    this.angularFirebase.database.ref('Parâmetros/modulo_canadian').once('value').then(val => { this.modulo_canadian = val.val() });
    this.angularFirebase.database.ref('Parâmetros/inversor_abb').once('value').then(val => { this.inversor_abb = val.val() });
    this.angularFirebase.database.ref('Parâmetros/estrutura_fixacao').once('value').then(val => { this.estrutura_fixacao = val.val() });
    this.angularFirebase.database.ref('Parâmetros/servico_instalacao').once('value').then(val => { this.servico_instalacao = val.val() });


    this.filter = new Filter();
  }

  ngOnInit() {
    this.angularFirebase.database.ref('users/' + this.afa.auth.currentUser.uid + '/isAdmim').once('value').then(val => {
      this.isAdmim = val.val()
    })
    this.angularFirebase.database.ref('users/' + this.afa.auth.currentUser.uid + '/isOwner').once('value').then(val => {
      this.isOwner = val.val()
    })
  }

  submit() {
    if (this.isAdmim) {
      let num2 = ((document.getElementById("aumento_distribuidora") as HTMLInputElement).value);
      let numx = ((document.getElementById("eficiencia_inversor") as HTMLInputElement).value);
      let num6 = ((document.getElementById("modulo_canadian") as HTMLInputElement).value);
      let num7 = ((document.getElementById("inversor_abb") as HTMLInputElement).value);
      let num8 = ((document.getElementById("estrutura_fixacao") as HTMLInputElement).value);
      let num9 = ((document.getElementById("servico_instalacao") as HTMLInputElement).value);
      this.angularFirebase.database.ref("Parâmetros/aumento_distribuidora").set(num2);
      this.angularFirebase.database.ref("Parâmetros/eficiencia_inversor").set(numx);
      this.angularFirebase.database.ref("Parâmetros/modulo_canadian").set(num6);
      this.angularFirebase.database.ref("Parâmetros/inversor_abb").set(num7);
      this.angularFirebase.database.ref("Parâmetros/estrutura_fixacao").set(num8);
      this.angularFirebase.database.ref("Parâmetros/servico_instalacao").set(num9).then(val => {

        this.parametrosService.lerBancoDeDados(this.databaseService);
      }).catch((error: any) => {
        swal({
          icon: 'error',
          title: 'Erro ao alterar!',
          text: 'Tente novamente mais tarde.',
          timer: 4000,
          buttons: {
            okay: {
              text: "OK"
            }
          }
        });
      });
    }

    else {
      swal({
        icon: 'error',
        title: 'Usuário sem permissão!',
        timer: 4000,
        buttons: {
          okay: {
            text: "OK"
          }
        }
      });
    }
  }
  admin() {
    if (this.isOwner) {
      this.router.navigate(['admim']);
    } else {
      swal({
        icon: 'error',
        title: 'Usuário sem permissão!',
        timer: 4000,
        buttons: {
          okay: {
            text: "OK"
          }
        }
      });
    }
  }
  inversers() {
    if (this.isAdmim) {
      this.router.navigate(['inversores']);
    } else {
      swal({
        icon: 'error',
        title: 'Usuário sem permissão!',
        timer: 4000,
        buttons: {
          okay: {
            text: "OK"
          }
        }
      });
    }
  }

}
