import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class ParametrosService {

  public eficiencia_inversor;
  public eficiencia_modulo;
  public aumento_distribuidora;
  public potencia_padrao
  public modulo_canadian;
  public servico_instalacao;
  public inversor_abb;
  public estrutura_fixacao;
  constructor(databaseService: DatabaseService) {
    this.lerBancoDeDados(databaseService);

  }

  lerBancoDeDados(databaseService: DatabaseService) {
    databaseService.getParametros().then(snap => {
      this.eficiencia_inversor = snap.val()['eficiencia_inversor'];
      this.eficiencia_modulo = snap.val()['eficiencia_modulo'];
      this.aumento_distribuidora = snap.val()['aumento_distribuidora'];
      this.potencia_padrao = snap.val()['potencia_padrao'];
      this.modulo_canadian = snap.val()['modulo_canadian'];
      this.inversor_abb = snap.val()['inversor_abb'];
      this.servico_instalacao = snap.val()['servico_instalacao'];
      this.estrutura_fixacao = snap.val()['estrutura_fixacao'];

    })
  }

}
