import { Injectable } from '@angular/core';
import { Users } from './providers/user-service/user-service'

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  usuarios: Users[] = [];
  constructor() { }
}
