import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase';
import { AngularFireDatabase } from "angularfire2/database";
import { AuthServiceProvider } from '../providers/auth-service/auth-service';
import { UserServiceProvider, User } from '../providers/user-service/user-service';
import { Router, RouterLink } from '@angular/router';


@Component({
  selector: 'app-cadastrar',
  templateUrl: './cadastrar.component.html',
  styleUrls: ['./cadastrar.component.scss']
})
export class CadastrarComponent implements OnInit {

  public names: string[];
  cadastroForm: FormGroup;
  public dadosForm: FormGroup;
  dados: User;

  constructor(
    public afa: AngularFireAuth,
    public authservice: AuthServiceProvider,
    public userService: UserServiceProvider,
    public formBuilder: FormBuilder,
    private router: Router,

  ) {
    let EmailRegex = /^[a-zA-Z0-9._]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;

    this.cadastroForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', Validators.compose([Validators.required, Validators.pattern(EmailRegex)])],
      senha: ['', [Validators.required, Validators.minLength(6)]],
      csenha: ['', [Validators.required, Validators.minLength(6)]],
      isAdmin: false,
      isOwner: false,
    });
  }

  ngOnInit() {
  }

  onSubmit(): void {
    let FormUser = this.cadastroForm.value;
    this.dados = new User(FormUser.name, FormUser.email, FormUser.isAdmin, FormUser.isOwner);
    this.authservice.createAuthUser({
      email: this.dados.email,
      senha: FormUser.senha
    }).then((authUser: firebase.User) => {
      let uuid: string = this.afa.auth.currentUser.uid;

      this.userService.createUser(this.dados, uuid)
        .then(() => {
          this.userService.setUuid(uuid);
          let user = firebase.auth().currentUser;
          user.sendEmailVerification()
            .then(() => {
              swal({
                icon: 'success',
                title: 'Cadastrado',
                text: 'Verifique seu LIXO ELETRÔNICO no e-mail e siga o link para confirmar seu e-mail',
                buttons: {
                  sim: {
                    text: "Ok"
                  }
                },
              })
                .then((value) => {
                  this.router.navigate(['proposta']);
                });
            })
        }).catch((error: any) => {
        });

    }).catch((error: any) => {
      if (error == 'The email address is already in use by another account.') {
        swal({
          icon: 'error',
          title: 'Email Inválido!',
          text: 'Este endereço de e-mail já está sendo utilizado.',
          timer: 4000,
          buttons: {
            okay: {
              text: "OK"
            }
          }
        });
      }
      if (error == 'The email address is badly formatted.') {
        swal({
          icon: 'error',
          title: 'Email Inválido!',
          text: 'Insira um e-mail com formato válido.',
          timer: 4000,
          buttons: {
            okay: {
              text: "OK"
            }
          }
        });
      }
      if (error == 'A network error (such as timeout, interrupted connection or unreachable host) has occurred.') {
        swal({
          icon: 'error',
          title: 'Erro de conexão!',
          timer: 4000,
          buttons: {
            okay: {
              text: "OK!"
            }
          }
        });
      }

    });
  }
}

