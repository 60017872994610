/*
Arquivo define o template do objeto proposta
*/
import { ParametrosService } from './parametros.service';
import { DatabaseService } from './database.service';

export class Proposta {

    public tipo: string; //Aluguel ou venda    
    public grupo: string;//A ou B

    public cliente: Cliente;
    public tarifas: Tarifas;
    public consumo: number;
    public n_modulos: number;
    public n_modulos_sugerido: number;
    public irradiacao;
    public producao_fv = [];
    public conta_antiga;
    public conta_nova = [];
    public vendedor;
    public inversores: Inversor[] = [];
    public potencia_placa: number;
    public potencia_real;
    public datas: Datas;
    public valor_total;

    public valor_aluguel: number;
    public payback_simples: number;

    public reducao_aluguel = [];
    public reducao_venda = [];
    public economia_aluguel = [];
    public economia_venda = []
    public timestamp;

    public parametros: ParametrosService;
    public proposta_reajuste;//Número da proposta sendo reajustada
    public reajuste: boolean = false;//Se essa proposta está sendo reajustada


    constructor() { }
    //Função responsável por realizar todos os cálculos  para geração da proposta
    //Funciona também para como forma de recalcular os valores
    gerarProposta(grupo, tipo, cliente, consumo: number, irradiacao, tarifas: Tarifas, parametros: ParametrosService, n_modulos: number,
        inversores, potencia_placa, datas: Datas, valor_total) {
        console.log(tarifas);
        this.tipo = tipo;
        this.grupo = grupo;
        this.cliente = cliente;
        this.consumo = consumo;
        this.irradiacao = irradiacao;
        this.tarifas = tarifas;
        let kwh_solar21 = this.tarifas.kwh_solar21;
        this.parametros = parametros;
        this.potencia_placa = potencia_placa;
        this.potencia_real;
        this.valor_total = valor_total;

        this.producao_fv = [];
        this.conta_antiga = [];
        this.conta_nova = [];
        this.reducao_aluguel = [];
        this.reducao_venda = [];
        this.economia_aluguel = [];
        this.economia_venda = [];
        this.datas = datas;

        this.inversores = inversores;


        let potencia = this.consumo * 1000 / (this.irradiacao['00_anual'] * 30 * (1 - this.parametros.eficiencia_inversor));
        this.n_modulos_sugerido = Math.floor(1000 * potencia / this.potencia_placa);
        let modulo;
        if (n_modulos > 0) {
            modulo = n_modulos;
        }
        else {
            modulo = this.n_modulos_sugerido;
        }
        this.potencia_real = modulo * this.potencia_placa / 1000;


        if (n_modulos == 0) {
            this.n_modulos = this.n_modulos_sugerido;
        }
        else {
            this.n_modulos = n_modulos;
        }
        this.valor_aluguel = 0;
        this.conta_antiga = this.tarifas.tarifa_total * this.consumo;
        console.log(this.conta_antiga,this.tarifas.tarifa_total,this.consumo)

        if (this.cliente.cidade == "Brasília" && this.cliente.estado == "DF") {
            this.conta_antiga += this.calcularCIP(consumo);
        }
        let producao_media = 0;
        let economia_anual = 0;
        let conta_anual = 0;
        for (let key in this.irradiacao) {
            if (key == "00_anual")
                continue;
            if (key == "capital")
                break;
            let producao_fv_mensal = Number(this.irradiacao[key]) * 30 * (1 - this.parametros.eficiencia_inversor) * modulo * this.potencia_placa / 1000000;

            producao_media += producao_fv_mensal;
        }
        if (this.tipo != "Compra") {
            this.valor_aluguel = kwh_solar21 * producao_media / 12;
            console.log(this.valor_aluguel);
        }
        for (let key in this.irradiacao) {
            if (key == "00_anual")
                continue;
            if (key == "capital")
                break;
            let producao_fv_mensal = Number(this.irradiacao[key]) * 30 * (1 - this.parametros.eficiencia_inversor) * modulo * this.potencia_placa / 1000000;

            this.producao_fv.push(producao_fv_mensal);
            producao_media += producao_fv_mensal;

            let conta_nova_mensal;
            let fornecia_rede = this.consumo - producao_fv_mensal;
            if (fornecia_rede < 100) {
                conta_nova_mensal = 100 * this.tarifas.tarifa_total;
            }
            /*else if(fornecia_rede<200){
                conta_nova_mensal=fornecia_rede*this.tarifas.tarifa_total;
            }
            else if(fornecia_rede<1000){
                conta_nova_mensal=fornecia_rede*this.tarifas.tarifa_total;
            }*/
            else {
                conta_nova_mensal = (this.consumo - producao_fv_mensal) * this.tarifas.tarifa_total;
            }
            if (this.cliente.cidade == "Brasília" && this.cliente.estado == "DF" && conta_nova_mensal != 0) {
                conta_nova_mensal += this.calcularCIP(this.consumo - producao_fv_mensal);
            }
            conta_anual += conta_nova_mensal;
            this.conta_nova.push(conta_nova_mensal);
            if (this.tipo != "Compra") {
                this.reducao_aluguel.push((this.conta_antiga - this.valor_aluguel - conta_nova_mensal) / this.conta_antiga);
                this.economia_aluguel.push(this.conta_antiga - conta_nova_mensal - this.valor_aluguel);//pergunta isso
                //economia_anual+=this.conta_antiga-conta_nova_mensal-this.valor_aluguel;
            }
            if (this.tipo != "Aluguel") {
                this.reducao_venda.push((this.conta_antiga - conta_nova_mensal) / this.conta_antiga);
                this.economia_venda.push(this.conta_antiga - conta_nova_mensal);
            }
        }
        for (let key in this.irradiacao) {
        }


        if (this.tipo != "Aluguel") {
            this.calcularPayback();
        } else {
            this.payback_simples = 0;
        }
        
    }

    //Calcula CIP no caso de brasília
    calcularCIP(consumo: number) {
        if (consumo <= 100) {
            return 7.62;
        } else if (consumo <= 180) {
            return 13.68;
        } else if (consumo <= 220) {
            return 16.74;
        } else if (consumo <= 300) {
            return 24.14;
        } else if (consumo <= 400) {
            return 32.18;
        } else if (consumo <= 500) {
            return 40.19;
        } else if (consumo <= 600) {
            return 48.22;
        } else if (consumo <= 700) {
            return 57.23;
        } else if (consumo <= 800) {
            return 64.23;
        } else if (consumo <= 900) {
            return 72.25;
        } else if (consumo <= 1000) {
            return 83.50;
        } else if (consumo <= 2000) {
            return 154.54;
        } else if (consumo <= 3000) {
            return 231.75;
        } else if (consumo <= 4000) {
            return 309.01;
        } else if (consumo <= 5000) {
            return 386.21;
        } else if (consumo <= 7000) {
            return 589.81;
        } else if (consumo <= 10000) {
            return 693.17;
        } else {
            return 702.62;
        }
    }

    //Retorna vetor com o fluxo de caixa acumulado
    calcularFluxoCaixa(anos) {

        let fluxo_caixa = [];

        let fluxo_caixa_acumulado = -this.valor_total;
        fluxo_caixa.push(fluxo_caixa_acumulado);
        let valor_anual_antigo = this.conta_antiga * 12;
        let valor_anual_novo = this.conta_nova.reduce(add, 0);
        function add(a, b) {
            return a + b;
        }
        let aumento = parseFloat(this.parametros.aumento_distribuidora)
        for (let i = 0; i < anos; i++) {
            let economia_ano_atual = valor_anual_antigo * Math.pow(1 + aumento, i) - valor_anual_novo * Math.pow(1 + aumento, i);
            fluxo_caixa_acumulado += economia_ano_atual;

            fluxo_caixa.push(fluxo_caixa_acumulado);
        }
        return fluxo_caixa;
    }

    calcularPayback() {
        let fluxo_caixa_acumulado = this.valor_total;

        let valor_anual_antigo = this.conta_antiga * 12;
        let valor_anual_novo = this.conta_nova.reduce(add, 0);

        function add(a, b) {
            return a + b;
        }

        let payback = 0; // Serve como contador

        let restante = 0;

        let aumento = parseFloat(this.parametros.aumento_distribuidora)
        while (fluxo_caixa_acumulado > 0) {
            let economia_ano_atual = valor_anual_antigo * Math.pow(1 + aumento, payback) - valor_anual_novo * Math.pow(1 + aumento, payback);
            restante = fluxo_caixa_acumulado / economia_ano_atual;
            fluxo_caixa_acumulado -= economia_ano_atual;
            payback++;
        }
        payback--;
        payback = payback + restante;
        this.payback_simples = payback;
    }
}

export class Cliente {

    constructor(public nome: string,
        public email: string,
        public telefone: string,
        public cidade: string,
        public estado: string,
        public numero: string,
        public contrato: string,
        public endereco,
        public CEP) { }
}

//classe que encapsula todas as tarifas
export class Tarifas {
    public icms: number;
    public pis: number;
    public cofins: number;
    public tarifa_total: number;
    public bandeira_tarifaria: number;
    public kwh_solar21: number;
    public kwh_concessionaria: number;


    constructor() {
        this.pis = 0.01;
        this.cofins = 0.03;
        this.kwh_solar21 = 0.55;
        this.bandeira_tarifaria = 0;
    }
    calcularTarifas(tarifasDB, distribuidoraDB) {
        this.icms = tarifasDB['ICMS C'];
        this.kwh_concessionaria = distribuidoraDB.valor;
        console.log(this.icms,this.kwh_concessionaria,this.bandeira_tarifaria)
        this.tarifa_total = (this.kwh_concessionaria + this.bandeira_tarifaria) / (1 - this.icms - this.pis - this.cofins);
    }
    calcularConcessionaria(valor){
        let kwh_concessionaria=this.tarifa_total*(1 - this.icms - this.pis - this.cofins)-this.bandeira_tarifaria;
        return kwh_concessionaria;
    }
}

export class Inversor {
    nome: string;
    potencia: number;
    numero: number;
    constructor() {
    }
}

export class Datas {
    public finalizacao_instalacao;
    public sistema_operante;
    public tempo_contrato
    constructor() { }
}



