import { Component, OnInit } from '@angular/core';
import { Proposta } from '../proposta';
import { Tarifas } from '../proposta';
import { Cliente } from '../proposta';
import { AngularFireAuth } from 'angularfire2/auth';
import { DatabaseService } from './../database.service';
import { AngularFireDatabase } from 'angularfire2/database';
import { PropostaService } from '../proposta.service';
import { Router, RouterLink } from '@angular/router';



@Component({
  selector: 'app-historico',
  templateUrl: './historico.component.html',
  styleUrls: ['./historico.component.scss']
})
export class HistoricoComponent implements OnInit {

  proposta: Proposta[] = [];
  contato: Cliente[] = [];
  nome: string;
  dataSting;
  type: string;
  contrato: number = 1;
  filtraramarela: boolean = true;
  filtrarverde: boolean = true;
  filtrarvermelha: boolean = true;
  cliente;
  numero_colunas = 4;

  public filtro_contrato = ['Proposta Enviada', 'Contrato Assinado', 'Proposta Recusada'];


  constructor(
    public afa: AngularFireAuth,
    public database: DatabaseService,
    public angularFirebase: AngularFireDatabase,
    public propostaService: PropostaService,
    public propostasService: PropostaService,
    private router: Router,
  ) {

    //this.sortProposes();
    this.inverterOrdem();//Colocar as últimas propostas feitas antes
    this.calcularColunas();//Calcular o número de colunas a serem colocadas dependendo do tamanho da tela

  }

  ngOnInit() {

  }
  calcularColunas() {
    if (window.innerWidth >= 1290) {
      this.numero_colunas = 5
    }
    else if (window.innerWidth < 1290 && window.innerWidth >= 1100) {
      this.numero_colunas = 4;
    }

    else if (window.innerWidth < 1100 && window.innerWidth > 830) {
      this.numero_colunas = 3;
    }
    else if (window.innerWidth <= 830 && window.innerWidth > 550) {
      this.numero_colunas = 2;
    }
    else {
      this.numero_colunas = 1;
    }

  }

  client(contrato: string) {//Selecionar a proposta escolhida e ir para a página cliente
    let proposes: Proposta[] = [];
    proposes = this.propostasService.propostas;

    for (let i = 0; i < proposes.length; i++) {
      if (proposes[i].cliente.numero == contrato) {
        this.propostaService.proposta = proposes[i];
        this.router.navigate(['cliente']);
      }
    }
  }

  mudar_filtro(filtro) {
    function arrayRemove(arr, value) {

      return arr.filter(function (ele) {
        return ele != value;
      });

    }

    if (filtro == 0) {
      if (this.filtro_contrato.includes('Proposta Enviada')) {
        this.filtro_contrato = arrayRemove(this.filtro_contrato, 'Proposta Enviada');
      } else {
        this.filtro_contrato.push("Proposta Enviada");
      }
    }
    if (filtro == 1) {
      if (this.filtro_contrato.includes('Contrato Assinado')) {
        this.filtro_contrato = arrayRemove(this.filtro_contrato, 'Contrato Assinado');
      } else {
        this.filtro_contrato.push("Contrato Assinado");
      }
    }
    if (filtro == 2) {
      if (this.filtro_contrato.includes('Proposta Recusada')) {
        this.filtro_contrato = arrayRemove(this.filtro_contrato, 'Proposta Recusada');
      } else {
        this.filtro_contrato.push("Proposta Recusada");
      }
    }
  }
  sortProposes() {//Caso queiram ordenar as propostas por ordem de tamanho
    let proposes: Proposta[] = [];
    proposes = this.propostasService.propostas;


    for (let i = 1; i < proposes.length; i++) {
      for (let j = i; j > 0; j--) {
        if (proposes[j].potencia_real > proposes[j - 1].potencia_real) {
          let temp = proposes[j];
          proposes[j] = proposes[j - 1];
          proposes[j - 1] = temp;
        }
      }
    }
    this.proposta = proposes;

  }
  inverterOrdem() {//Inverter ordem das propostas a serem mostradas

    let proposes: Proposta[] = [];
    proposes = this.propostasService.propostas;
    let a = proposes.length;

    for (let i = 0; i < proposes.length / 2; i++) {
      let temp = proposes[a - i - 1];
      proposes[a - i - 1] = proposes[i];
      proposes[i] = temp;


    }

    this.proposta = proposes;
  }
}





