import { ParametrosService } from './../parametros.service';
import { PropostaService } from './../proposta.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatabaseService } from '../database.service';
import { AngularFireDatabase } from 'angularfire2/database';
import * as html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import { AngularFireAuth } from 'angularfire2/auth';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn, FormArray } from "@angular/forms";
import { Proposta, Tarifas, Cliente, Inversor, Datas } from './../proposta';
import { Filter } from './../cliente/cliente.component';

import * as firebase from 'firebase';



@Component({
  selector: 'app-analise',
  templateUrl: './analise.component.html',
  styleUrls: ['./analise.component.scss']
})
export class AnaliseComponent implements OnInit {

  public dadosForm: FormGroup;
  public number: number;
  tipo: string;
  vendedor;
  ano;
  items: FormArray;
  public inversores: Inversor[] = [];
  passar = false;
  filter: Filter;
  potencia;
  fator_ajuste;
  consumo;
  potencia_real;
  datas;
  boolean_recalculou: boolean = false;
  fdi;


  constructor(public angularFirebase: AngularFireDatabase, public proposta: PropostaService, public formBuilder: FormBuilder, private router: Router, public dbService: DatabaseService, public parametrosService: ParametrosService, public afa: AngularFireAuth) { }
 
  //Inicialização de variáveis
  ngOnInit() {
    this.datas = new Datas();//Saber qual é a data de criação da proposta

    this.filter = new Filter();//Filtro para formatar os números

    this.inversores = this.dbService.getInversores();//Pegando a lista de inversores do banco de dados

    this.tipo = this.proposta.proposta.tipo;
    let data = Date.now();

    let timestamp = new Date(data);
    this.ano = timestamp.getFullYear();

    this.angularFirebase.database.ref('users/' + this.afa.auth.currentUser.uid + '/name').once('value').then(val => {
      this.vendedor = val.val()
    })//Saber qual é o nome do vendedor






    this.dadosForm = this.formBuilder.group({//Definindo o formulário
      potencia: ['', [Validators.required]],
      consumo: ['', [Validators.required]],
      kwh_solar21: ['', [Validators.required]],
      n_modulos: ['', [Validators.required]],
      potencia_placa: ['', [Validators.required]],
      fator_ajuste: ['', [Validators.required]],
      //equipamento_frete:['',[Validators.required]],
      //projeto:['',[Validators.required]],
      //instalacao:['',[Validators.required]],
      //diaria_ajudante:['',[Validators.required]],
      //material_ca:['',[Validators.required]],
      //bdi:['',[Validators.required]],
      valor_total: ['', [Validators.required]],
      finalizacao_instalacao: ['', [Validators.required]],
      sistema_operante: ['', [Validators.required]],
      tempo_contrato: ['', [Validators.required]],
      kwh_concessionaria: ['', [Validators.required]],
      items: this.formBuilder.array([this.initTelFields()])
    });
    //Colocando os valores já existentes no formulário de análise
    this.dadosForm.get('potencia').setValue(this.filter.filter(parseFloat(this.proposta.proposta.potencia_real)));
    this.dadosForm.get('consumo').setValue(this.proposta.proposta.consumo);
    this.dadosForm.get('kwh_solar21').setValue(this.proposta.proposta.tarifas.kwh_solar21);
    this.dadosForm.get('n_modulos').setValue(this.proposta.proposta.n_modulos);
    this.dadosForm.get('potencia_placa').setValue(this.proposta.proposta.potencia_placa);
    this.dadosForm.get('fator_ajuste').setValue(1);
    this.dadosForm.get('finalizacao_instalacao').setValue(80);
    this.dadosForm.get('sistema_operante').setValue(90);
    let tarifa= Math.floor(this.proposta.proposta.tarifas.tarifa_total*100)/100;
    this.dadosForm.get('kwh_concessionaria').setValue(tarifa);
    this.fator_ajuste = 1;
    if (this.proposta.proposta.datas != null) {
      this.dadosForm.get('tempo_contrato').setValue(this.proposta.proposta.datas.tempo_contrato);
    }
    if (this.proposta.proposta.inversores) {
      let inversor = this.dadosForm.get('items').value;
      inversor[0].nome = this.proposta.proposta.inversores[0].nome;
      inversor[0].quantidade = this.proposta.proposta.inversores[0].numero;
      this.dadosForm.get('items').setValue(inversor);
    }
    if (this.proposta.proposta.valor_total > 0) {
      this.dadosForm.get('valor_total').setValue(this.proposta.proposta.valor_total);
    }
    //this.dadosForm.get('items').setValue(this.proposta.proposta.inversores);


  }

  //Recalcula os valores da proposta baseado nas informações no form
  recalcular() {
    let inversor = this.dadosForm.get('items').value;

    let inversers: Inversor[] = [];
    let inversers2 = this.inversores;

    let potencia_total = 0;


    inversor.forEach(function (element) {//Transformando os inversores em uma objeto de uma classe
      inversers2.forEach(function (db) {
        if (db.nome == element.nome) {
          let inversor_form = new Inversor();
          inversor_form.nome = db.nome;
          inversor_form.numero = element.quantidade;
          inversor_form.potencia = db.potencia;
          inversers.push(inversor_form);
          potencia_total += db.potencia * element.quantidade;
        }
      })
    });

    //Fazenddo as contas para conseguir calcular o fdi
    let potencia_temporaria = this.proposta.proposta.potencia_real * this.dadosForm.get('consumo').value / this.proposta.proposta.consumo;
    let n_modulos_sugerido = Math.floor(1000 * potencia_temporaria / this.dadosForm.get('potencia_placa').value);
    this.potencia_real = this.dadosForm.get('n_modulos').value * this.dadosForm.get('potencia_placa').value / 1000;

    let fdi = potencia_total / this.potencia_real;
    this.proposta.proposta.n_modulos_sugerido = n_modulos_sugerido;
    this.dadosForm.get('potencia').setValue(this.potencia_real);

    this.fdi = fdi;

    if ((fdi >= 0.75 && fdi <= 1.05) || this.passar == true) {


      this.boolean_recalculou = true;
      this.proposta.proposta.tarifas.kwh_solar21 = this.dadosForm.get('kwh_solar21').value;
      let kwh_concessionaria=this.proposta.proposta.tarifas.calcularConcessionaria(this.dadosForm.get('kwh_concessionaria').value)
  
      if (this.proposta.proposta.tarifas.tarifa_total != this.dadosForm.get('kwh_concessionaria').value) {
        
        this.dbService.setTarifaConcessionaria(this.proposta.proposta.cliente.estado, this.proposta.proposta.cliente.cidade, kwh_concessionaria);
        this.proposta.proposta.tarifas.tarifa_total=parseFloat(this.dadosForm.get('kwh_concessionaria').value);
      }
      this.proposta.proposta.tarifas.kwh_concessionaria = kwh_concessionaria;
      this.proposta.proposta.gerarProposta(this.proposta.proposta.grupo, this.proposta.proposta.tipo, this.proposta.proposta.cliente, this.dadosForm.get('consumo').value, this.proposta.proposta.irradiacao, this.proposta.proposta.tarifas, this.parametrosService, this.dadosForm.get('n_modulos').value, inversers, this.dadosForm.get('potencia_placa').value, null, this.dadosForm.get('valor_total').value);
      swal({
        icon: 'success',
        title: 'Recalculado com sucesso',
        buttons: {
          sim: {
            text: "ok"
          }
        }
      })

    } else {
      swal({
        icon: 'error',
        title: 'FDI fora do recomendado',
        text: 'Fator de dimensionamento de inversor está ' + this.filter.filter(fdi),
        buttons: {
          sim: {
            text: "Continuar"
          },
          nao: {
            text: "Mudar"
          }
        }
      }).then((value) => {
        if (value == 'sim') {//Se decidir continuar, mesmo com o fdi fora do normal, faz-se o cálculo
          this.proposta.proposta.tarifas.kwh_solar21 = this.dadosForm.get('kwh_solar21').value;
          this.proposta.proposta.gerarProposta(this.proposta.proposta.grupo, this.proposta.proposta.tipo, this.proposta.proposta.cliente, this.dadosForm.get('consumo').value, this.proposta.proposta.irradiacao, this.proposta.proposta.tarifas, this.parametrosService, this.dadosForm.get('n_modulos').value, inversers, this.dadosForm.get('potencia_placa').value, null, this.dadosForm.get('valor_total').value);
          this.passar = true;
          this.boolean_recalculou = true;
        } else {

        }
      });
    }
  }

  //Armazena a proposta no banco de dados
  salvarProposta() {

    this.angularFirebase.database.ref('Parâmetros/Contador/' + this.tipo).once('value').then(val => {

      this.number = val.val() + 1;//Contador de aluguel ou venda ou aluguel e venda


      if (this.tipo == "Compra") {

        if (this.number < 10) {
          this.proposta.proposta.cliente.numero = "V100" + this.number + '_' + this.ano;
        }
        else if (this.number >= 10 && this.number < 100) {
          this.proposta.proposta.cliente.numero = "V10" + this.number + '_' + this.ano;
        }
        else if (this.number >= 100 && this.number < 1000) {
          this.proposta.proposta.cliente.numero = "V1" + this.number + '_' + this.ano;
        }
        else {
          this.proposta.proposta.cliente.numero = "V" + this.number + '_' + this.ano;
        }
      } else if (this.tipo == 'Aluguel') {
        if (this.number < 10) {
          this.proposta.proposta.cliente.numero = "A100" + this.number + '_' + this.ano;
        }
        else if (this.number >= 10 && this.number < 100) {
          this.proposta.proposta.cliente.numero = "A10" + this.number + '_' + this.ano;
        }
        else if (this.number >= 100 && this.number < 1000) {
          this.proposta.proposta.cliente.numero = "A1" + this.number + '_' + this.ano;
        }
        else {
          this.proposta.proposta.cliente.numero = "A" + this.number + '_' + this.ano;
        }
      }
      else {
        if (this.number < 10) {
          this.proposta.proposta.cliente.numero = "AV00" + this.number + '_' + this.ano;
        }
        else if (this.number >= 10 && this.number < 100) {
          this.proposta.proposta.cliente.numero = "AV0" + this.number + '_' + this.ano;
        }

        else if (this.number >= 100 && this.number < 100) {
          this.proposta.proposta.cliente.numero = "AV0" + this.number + '_' + this.ano;
        }
        else {
          this.proposta.proposta.cliente.numero = "AV" + this.number + '_' + this.ano;
        }

      }
      if (this.dadosForm.get("sistema_operante").value == '') {
        swal({
          icon: 'error',
          title: 'Tempo para sistema operante',
          timer: 4000,
          buttons: {
            okay: {
              text: "OK"
            }
          }
        });
      } else if (this.dadosForm.get("finalizacao_instalacao").value == '') {
        swal({
          icon: 'error',
          title: 'Tempo para finalização de instalação',
          timer: 4000,
          buttons: {
            okay: {
              text: "OK"
            }
          }
        });
      } else if (this.dadosForm.get("tempo_contrato").value == '' && this.proposta.proposta.tipo == 'Aluguel') {
        swal({
          icon: 'error',
          title: 'Tempo de contrato obrigatório',
          timer: 4000,
          buttons: {
            okay: {
              text: "OK"
            }
          }
        });
      } else if (this.boolean_recalculou == false) {
        swal({
          icon: 'error',
          title: 'Recalcular é obrigatório',
          timer: 4000,
          buttons: {
            okay: {
              text: "OK"
            }
          }
        });

      } else if (this.dadosForm.get("consumo").value != this.proposta.proposta.consumo || this.dadosForm.get("potencia_placa").value != this.proposta.proposta.potencia_placa || this.dadosForm.get("kwh_solar21").value != this.proposta.proposta.tarifas.kwh_solar21 || this.dadosForm.get("n_modulos").value != this.proposta.proposta.n_modulos || this.dadosForm.get("kwh_concessionaria").value != this.proposta.proposta.tarifas.tarifa_total) {
        console.log(this.dadosForm.get("kwh_concessionaria").value,this.proposta.proposta.tarifas.tarifa_total)
        swal({
          icon: 'error',
          title: 'Recalcular é obrigatório',
          timer: 4000,
          buttons: {
            okay: {
              text: "OK"
            }
          }
        });
      } else if (this.fdi > 0) {//FDI maior que  0 para impedir que não tenha colocado nenhuma quantidade de inversores
        this.datas.sistema_operante = this.dadosForm.get('sistema_operante').value;
        this.datas.finalizacao_instalacao = this.dadosForm.get('finalizacao_instalacao').value;
        this.datas.tempo_contrato = this.dadosForm.get('tempo_contrato').value;
        this.proposta.proposta.vendedor = this.vendedor;
        this.proposta.proposta.valor_total = this.dadosForm.get('valor_total').value;
        this.proposta.proposta.potencia_real = this.filter.filter(this.proposta.proposta.potencia_real);
        this.proposta.proposta.datas = this.datas;

        if (this.proposta.proposta.reajuste) {//Caso ele esteja editando a proposta, para não repetir o contador de cada tipo de proposta
          this.dbService.deletarProposta(this.proposta.proposta.proposta_reajuste).then(() => {//Deletando a proposta e criando uma mesma só que com as novas informações
            this.proposta.proposta.cliente.numero = this.proposta.proposta.proposta_reajuste;
            this.dbService.novaProposta(this.proposta.proposta, this.proposta.proposta.proposta_reajuste);//this.proposta.proposta.proposta_reajuste é o número da proposta sendo editada
            this.proposta.proposta = this.proposta.proposta;
            this.proposta.proposta.reajuste = false;
            this.dbService.historicoPropostas();//Atualizar a lista de propopostas
            this.router.navigate(['cliente']);
          });
        }
        else {//Caso esteja criando proposta
          this.angularFirebase.database.ref("Parâmetros/Contador/" + this.tipo).set(this.number);
          this.dbService.novaProposta(this.proposta.proposta, this.proposta.proposta.cliente.numero);
          this.proposta.proposta = this.proposta.proposta;
          this.dbService.historicoPropostas();
          this.router.navigate(['cliente']);
          console.log(this.proposta.proposta)
        }
      } else {
        swal({
          icon: 'error',
          title: 'Inversores são obrigatórios',
          timer: 4000,
          buttons: {
            okay: {
              text: "OK"
            }
          }
        });
      }
    })
  }
  //Formulário dinâmico para os inversores
  addNewTelField(): void {
    const controls = <FormArray>this.dadosForm.controls.items;
    controls.push(this.initTelFields());
  }

  removeTelField(i: number): void {
    const controls = <FormArray>this.dadosForm.controls.items;
    controls.removeAt(i);
  }
  initTelFields(): FormGroup {
    return this.formBuilder.group({
      nome: ["", Validators.required],
      quantidade: ["", Validators.required]
    });
  }
  direcionar(link) {//Para não permitir que saia da página de análise perdendo as informações
    swal({
      icon: 'warning',
      text: 'As informações dessa página serão perdidas',
      buttons: {
        sim: {
          text: "Continuar",
          className: "sweet"
        },
        nao: {
          text: "Ficar",
          className: "sweet"
        }
      }
    }).then((value) => {
      if (value == 'sim') {
        this.router.navigate([link]);
      }
    });
  }
}
