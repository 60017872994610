import { AngularFireAuth } from 'angularfire2/auth';
import { BaseServiceProvider } from '../base-service/base-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'rxjs/add/operator/first';

@Injectable()
export class AuthServiceProvider extends BaseServiceProvider {

  constructor(
    public afAuth: AngularFireAuth,
    public http: HttpClient
  ) {
    super();
  }
  createAuthUser(user: { email: string, senha: string }): Promise<firebase.User> {
    return this.afAuth.auth.createUserWithEmailAndPassword(user.email, user.senha)
      .catch(this.handlePromiseError);
  }
  signinWithEmail(user: { email: string, senha: string }): Promise<boolean> {
    return this.afAuth.auth.signInWithEmailAndPassword(user.email, user.senha)
      .then((authUser: firebase.auth.UserCredential) => {
        return authUser != null;
      }).catch(this.handlePromiseError);
  }
  get authenticated(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.afAuth
        .authState
        .first()
        .subscribe((authUser: firebase.User) => {
          (authUser) ? resolve(true) : reject(false);
        });
    });
  }

}